import React from "react";
import { connect } from "react-redux";
import Octicon from "react-octicon";
import { DropzoneButton } from "AppSrc/utils/dropZone/dropZoneHooks";
import Traec from "traec";
import { HTMLText } from "traec/utils/html";
import { Tooltip } from "react-tippy";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import DocumentHistoryRows from "./documentHistoryRows";
import { getNodeFromPath, getPathChildren } from "traec/utils/nodes";
import chroma from "chroma-js";
import Moment from "moment";
import { ReportDocumentFormButton } from "./reportDocumentForm";
import { ReportDocumentVerticalFormButton } from "./reportDocumentFormVertical";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { deleteDocumentObject } from "./documentHistoryRows";
import { RequiredStar } from "AppSrc/project/metrics/documentRow";

export function DocumentTitleTooltip({ doc, indentLevel, isProjectAdmin }) {
  let title = doc ? doc.getInPath("description.title") || doc.get("name").substring(0, 8) : null;

  let descr = doc.getInPath("description.text") || "";
  let toolTipHtml = (
    <div className="text-left">
      <div dangerouslySetInnerHTML={{ __html: descr.trim() }} />
      {isProjectAdmin ? <p style={{ marginTop: "1rem" }}>Doc id: {doc?.get("uid")?.substring(0, 8)}</p> : null}
    </div>
  );

  return (
    <div style={{ margin: "0", marginLeft: `${(indentLevel + 1) * 1.5}em` }}>
      {title}
      <RequiredStar document={doc} />
      <Tooltip animateFill={false} html={toolTipHtml}>
        <Octicon name="info" className="ml-2" />
      </Tooltip>
    </div>
  );
}

export const downloadS3Object = ({ trackerId, commitId, docId, currentDocObject }) => {
  let _fetch = new Traec.Fetch("tracker_commit_document_object", "retrieve", {
    trackerId,
    commitId,
    docId,
    docObjectId: currentDocObject.get("uid"),
    signedURL: true
  });
  _fetch.updateFetchParams({
    throttleTimeCheck: 1,
    cacheTime: 1,
    postSuccessHook: data => {
      console.log("Got file data", data);
      if (data.signed_url) {
        console.log("Downloading file", data.signed_url);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = data.signed_url;
        a.download = data.filename || "unknown.file";
        document.body.appendChild(a);
        a.click();
      }
    }
  });
  _fetch.dispatch();
};

export function CurrentObject(props) {
  let { currentDocObject, errors, status, hide, noFileMessage } = props;
  if (hide) {
    return null;
  }

  if (!currentDocObject) {
    return <span className="float-left text-muted">{noFileMessage ? noFileMessage : `No file`}</span>;
  }

  if (status && status === "sent") {
    return <span className="alert-warning float-left">Uploading... please wait.</span>;
  }

  if (errors || status === "failed") {
    console.log("ERRORS IN DOCUMENT UPLOAD", errors);
    if (errors === "Request Entity Too Large") {
      errors = "Document too large! Maximum allowed upload size is 500Mb";
    } else {
      errors = "Error in upload.  Maximum allowed upload size is 500Mb";
    }
    return <span className="alert-danger float-left">{errors}</span>;
  }

  let filename = currentDocObject ? currentDocObject.get("filename") : null;
  let url = currentDocObject ? currentDocObject.get("signed_url") : null;

  let isUploaded = currentDocObject ? !(currentDocObject.created && currentDocObject.virus_checked) : false;

  return (
    <ErrorBoundary>
      {isUploaded ? <Octicon name="check" className="float-left" /> : <Octicon name="file" />}
      <a
        style={{ cursor: "pointer", color: "#007bff", textDecoration: "underline" }}
        className="link-primary"
        onClick={e => {
          e.preventDefault();
          downloadS3Object({ ...props });
        }}
      >
        {filename}
      </a>
    </ErrorBoundary>
  );
}

const updateDocStatus = ({ trackerId, commitId, refId, path: pathId, statusName }) => {
  let fetch = new Traec.Fetch("tracker_node", "put", {
    trackerId,
    commitId,
    refId,
    pathId,
    allow_commit_change: true
  });
  // Update the body
  fetch.updateFetchParams({
    body: {
      type: "document",
      node: {
        document: {
          status: {
            status: { name: statusName }
          }
        }
      }
    }
  });
  // Send the request
  fetch.dispatch();
};

export function DocumentNoReportCheckbox(props) {
  let { path: pathId, docStatus, disableInputs } = props;
  let noReport = docStatus?.getInPath("status.name") == "Not for Submission";
  return (
    <input
      type="checkbox"
      className={`m-0 p-0`}
      checked={noReport}
      disabled={disableInputs}
      onChange={e => {
        e.preventDefault();
        let statusName =
          docStatus?.getInPath("status.name") != "Not for Submission" ? "Not for Submission" : "OK for Submission";
        console.log("CHECKED NO REPORT for document at path", pathId, docStatus?.toJS());
        console.log("Changing document status to: ", statusName);
        updateDocStatus({ ...props, statusName });
      }}
      name={"noreport"}
    />
  );
}

function SelectFile(props) {
  let {
    hide,
    doc,
    disableInputs,
    selectedFiles,
    onDrop,
    onCancelUpload,
    onConfirmHandler,
    isUploadingFile,
    startIsUploadingFile,
    stopIsUploadingFile
  } = props;

  if (hide || disableInputs) {
    return null;
  }

  let files = selectedFiles.map(file => (
    <a key={file.name}>
      Upload {file.name}? ({(file.size / 1e6).toFixed(1)}Mb)
    </a>
  ));

  // Give a warning if the file is too large
  let confirmButton = null;
  if (selectedFiles.filter(file => file.size / 1e6 > 500).length) {
    files = [
      <span key={0} className="alert-danger">
        Maximum allowed upload size is 500Mb
      </span>
    ];
  } else {
    confirmButton = (
      <BSBtn text={"Upload"} onClick={onConfirmHandler} extra_className="pl-1 pr-1 m-0 p-0" noFloatRight={true} />
    );
  }

  return (
    <div className="float-right">
      <DropzoneButton
        onDrop={onDrop}
        extra_className="pl-1 pr-1 m-0 p-0"
        selectAreaText="Select file"
        confirmButton={confirmButton}
        selectedFiles={files}
        onCancelUpload={onCancelUpload}
        isUploadingFile={isUploadingFile}
        startIsUploadingFile={startIsUploadingFile}
        stopIsUploadingFile={stopIsUploadingFile}
      />
    </div>
  );
}

class ReportDocumentRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: [],
      uploadedFile: false,
      showHistory: props?.doc?.getInPath("meta_json.showHistory") === false ? false : true,
      isUploadingFile: false
    };

    this.confirmDocumentUpload = this.confirmDocumentUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onCancelUpload = this.onCancelUpload.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.clearFile = this.clearFile.bind(this);
    this.startIsUploadingFile = this.startIsUploadingFile.bind(this);
    this.stopIsUploadingFile = this.stopIsUploadingFile.bind(this);
  }

  getUrlParams() {
    let { cref, commitId, treeId, doc } = this.props;
    let pathId = doc ? doc.get("path") : doc;
    let trackerId = cref.get("tracker");
    let refId = cref.get("uid");
    return { trackerId, refId, commitId, treeId, pathId };
  }

  uploadFile(docId) {
    //e.preventDefault();
    let { trackerId, commitId, refId, path: pathId } = this.props;

    let fetch = new Traec.Fetch(
      "tracker_node",
      "put",
      {
        trackerId,
        commitId,
        refId,
        pathId,
        allow_commit_change: true
      },
      {
        preDispatchHook: () => {
          this.setState({ isUploadingFile: true });
        }
      }
    );
    // Add the file object to the form and dispatch
    let formData = new FormData();
    formData.append("fileobj", this.state.selectedFiles[0]);
    formData.append("type", "document");
    formData.append("path", pathId);
    //fetch.updateFetchParams({ body: formData });
    // You can send this as a raw file if you set:
    // headers: { "content-type": null: "content-disposition": "attachment; filename=upload.jpg" },
    // body: this.state.selectedFiles[0]
    fetch.updateFetchParams({
      headers: { "content-type": null },
      rawBody: true,
      body: formData,
      postSuccessHook: () => {
        let _fetch = new Traec.Fetch("tracker_commit_document_object", "list", {
          trackerId,
          commitId,
          docId,
          path: pathId,
          thisCommitOnly: true
        });
        _fetch.updateFetchParams({ throttleTimeCheck: 1, cacheTime: 1 });
        console.log("Fetching possible updated file history");
        _fetch.dispatch();
        this.setState({ isUploadingFile: false });
      }
    });
    fetch.dispatch();
    // Set the state here
    this.setState({
      uploadedFile: this.state.selectedFiles[0].name,
      selectedFiles: []
    });
  }

  clearFile() {
    let { docStatus, trackerId, commitId, refId, docId, currentDocObject } = this.props;

    // This actually deletes the current uploaded document
    //let docObjectId = currentDocObject.get("uid")
    //return deleteDocumentObject({ trackerId, commitId, docId, docObjectId })

    // This just clears the current status
    let pathId = docStatus.get("_path");
    let fetch = new Traec.Fetch("tracker_node", "patch", {
      trackerId,
      commitId,
      refId,
      pathId
    });
    fetch.updateFetchParams({
      body: {
        type: "documentstatus",
        node: {
          documentstatus: {
            current_object: null
          }
        }
      }
    });
    confirmDelete({
      text: `This will clear the current uploaded document.  It will still appear in the upload history.  Would you like to proceed?`,
      onConfirm: () => {
        fetch.dispatch();
      }
    });
  }

  confirmDocumentUpload(e, docId) {
    e.preventDefault();

    if (this.state.selectedFiles) {
      this.uploadFile(docId);
    }
  }

  onDrop(files) {
    this.setState({ selectedFiles: files, isUploadingFile: false });
  }

  onCancelUpload() {
    this.setState({ selectedFiles: [], isUploadingFile: false });
  }

  startIsUploadingFile() {
    this.setState({ isUploadingFile: true });
  }

  stopIsUploadingFile() {
    this.setState({ isUploadingFile: false });
  }

  render() {
    let {
      doc,
      rowColor,
      indentLevel,
      trackerId,
      commitId,
      path,
      currentDocObject,
      disableInputs,
      docStatus,
      isProjectAdmin
    } = this.props;
    if (!doc) {
      return null;
    }

    let noReport = docStatus?.getInPath("status.name") == "Not for Submission";
    let isForm = doc.getInPath("meta_json.input_type") == "form";
    let isFormVertical = doc.getInPath("meta_json.input_type") == "formvertical";

    if (isFormVertical) {
      return (
        <ReportDocumentVerticalFormButton
          readOnly={disableInputs}
          doc={doc}
          currentDocObject={currentDocObject}
          docStatus={docStatus}
          rowColor={rowColor}
          indentLevel={indentLevel}
          trackerId={trackerId}
          commitId={commitId}
          path={path}
        />
      );
    }

    if (isForm) {
      return (
        <ReportDocumentFormButton
          readOnly={disableInputs}
          doc={doc}
          currentDocObject={currentDocObject}
          docStatus={docStatus}
          rowColor={rowColor}
          indentLevel={indentLevel}
          trackerId={trackerId}
          commitId={commitId}
          path={path}
        />
      );
    }

    return (
      <React.Fragment>
        <tr style={{ backgroundColor: rowColor }}>
          <td className="border-0">
            <DocumentTitleTooltip doc={doc} indentLevel={indentLevel} isProjectAdmin={isProjectAdmin} />
          </td>

          <td colSpan={3} className="border-0">
            <CurrentObject {...this.props} hide={noReport} />
            <SelectFile
              {...this.props}
              hide={noReport}
              selectedFiles={this.state.selectedFiles}
              onConfirmHandler={e => this.confirmDocumentUpload(e, doc ? doc.get("uid") : null)}
              onDrop={this.onDrop}
              onCancelUpload={this.onCancelUpload}
              isUploadingFile={this.state.isUploadingFile}
              startIsUploadingFile={this.startIsUploadingFile}
              stopIsUploadingFile={this.stopIsUploadingFile}
            />
          </td>
          <td className="text-center border-0">
            <DocumentNoReportCheckbox {...this.props} />
          </td>
          <td className="border-0">
            <BSBtnDropdown
              links={[
                {
                  name: `${this.state.showHistory ? "Hide" : "Show"} History`,
                  onClick: e => {
                    this.setState({ showHistory: !this.state.showHistory });
                  }
                },
                disableInputs
                  ? {}
                  : {
                      name: `Clear current upload`,
                      onClick: e => {
                        this.clearFile();
                      }
                    }
              ]}
            />
          </td>
        </tr>
        {this.state.showHistory ? (
          <DocumentHistoryRows
            {...this.props}
            hide={!this.state.showHistory}
            docId={doc?.get("uid")}
            path={path}
            indentLevel={indentLevel + 1}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const get_color = (commit, lastUpdate) => {
  if (!lastUpdate || !commit) {
    return null;
  }
  let color = null;
  let firstCommit = commit.getInPath("meta_json.history.0.updateOn");
  if (lastUpdate && firstCommit) {
    if (Moment(lastUpdate).diff(Moment(firstCommit)) > 0) {
      color = chroma("#bf80ff")
        .brighten(2)
        .hex();
    }
  }
  return color;
};

const mapStateToProps = (state, ownProps) => {
  let { trackerId, commitId, path, commitNodes } = ownProps;

  // Get the documeent and description
  let doc = getNodeFromPath(state, path, commitNodes);
  let docId = doc ? doc.get("uid") : null;

  // Nest the description inside of the doc object
  let description = getPathChildren(state, path, commitNodes, "descriptions").first() || Traec.Im.Map();
  doc = doc ? doc.set("description", description) : doc;

  // Get the status and current object
  let docStatus = getPathChildren(state, path, commitNodes, "documentstatus").first() || Traec.Im.Map();
  let docObject = docStatus ? docStatus.get(`current_object`) : null;

  // Get the currentDocObject
  let currentDocObject = false;
  currentDocObject = docObject
    ? docObject.get("commit") === commitId
      ? docObject.merge(state.getInPath(`entities.docObjects.byId.${docObject.get("uid")}`) || Traec.Im.Map())
      : null
    : null;

  // Get the cache response and any errors
  let fetch = new Traec.Fetch("tracker_commit_document", "put", {
    trackerId,
    commitId,
    documentId: docId,
    allow_commit_change: true
  });
  let fetchCache = fetch.redux_cache_object;
  let errors = fetchCache ? fetchCache.get(`errors`) : null;
  let status = fetchCache ? fetchCache.get(`status`) : null;

  // Get the color of this row
  let rowColor = get_color(
    state.getInPath(`entities.commits.byId.${commitId}`),
    currentDocObject ? currentDocObject.get("created") : null
  );

  return { currentDocObject, docStatus, rowColor, errors, status, fetchCache, doc, docId };
};

export default connect(mapStateToProps)(ReportDocumentRow);
