import Moment from "moment";
import Traec from "traec";

import { getNodeFromPath, getParentNodeFromPath } from "traec/utils/nodes";
import { getConversionFactor } from "../metrics/metricRow";
import { setUIItems } from "traec/redux/actionCreators";
import { nestBaseMetric } from "./reportMetricRow";

import store from "traec/redux/store";

export const reportingPeriodText = item => {
  if (!item) {
    return null;
  }
  return `${Moment(item.get("startDate")).format("Do MMM YY")} to ${Moment(item.get("endDate"))
    .add(-1, "days")
    .format("Do MMM YY")}`;
};

export const dateToText = (date, offsetDays = -1) => {
  if (!date) return null;
  return `${Moment(date)
    .add(offsetDays, "days")
    .format("Do MMM YY")}`;
};

export const getLatestValue = values => {
  if (!values) {
    return null;
  }
  let latestTime = null;
  let latestValue = null;

  for (let [valueId, value] of values) {
    if (!latestTime) {
      latestValue = value;
      latestTime = new Date(value.get("created"));
    } else if (new Date(value.get("created")) >= latestTime) {
      latestValue = value;
      latestTime = new Date(value.get("created"));
    }
  }
  return latestValue;
};

export const get_conversion = ({ score, value, conversionFactor, noReport }) => {
  let factor = conversionFactor ? conversionFactor.get("factor") : null;
  //console.log(`Getting conversion: *${value}*, *${noReport}*`);

  if (noReport) {
    return { value: "noreport" };
  }

  if (value === null || value === undefined || value === "" || !conversionFactor || factor === null) {
    /*console.log(
      "get_conversion returning null because",
      value,
      conversionFactor ? conversionFactor.toJS() : conversionFactor,
      factor
    );*/
    return null;
  }

  let parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    //console.log("get_conversion returning null because value provided is NaN");
    return null;
  }

  let fromMetric = conversionFactor.get("metric");
  let fromUnit = fromMetric.get("units") || fromMetric.get("unit");
  let toUnit = conversionFactor.get("toUnit");

  // Default conversion method
  let convertedValue = parsedValue * parseFloat(factor);
  //console.log("AAA get_conversion", fromMetric?.toJS(), fromUnit, toUnit, factor, convertedValue)

  // Handle boolean inputs differently: for boolean children always return a converted value of 1.
  // This means the parent is a count of the number of children that have a true OR false response (any response)
  let inputType = (score ? score.getInPath("meta_json.input_type") : null) || "standard";
  if (inputType == "boolean") {
    convertedValue = 1;
  }

  return {
    fromMetric,
    toUnit,
    fromUnit,
    inputValue: value,
    convertedValue,
    conversionFactor: factor,
    inputType
  };
};

export const setInitialConversions = (commitId, commitNodes, convFactorDetails) => {
  console.log("Running setInitialConversions for report", convFactorDetails);
  let state = store.getState();

  let scoreValues = commitId ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) : null;

  let nodesByPath = commitNodes.get("byPath");
  let initConversionMap = Traec.Im.Map();

  for (let path of nodesByPath.keySeq()) {
    // Get the node and parent
    let score = nestBaseMetric(state, getNodeFromPath(state, path, commitNodes, "metricScores"));
    let parentScore = nestBaseMetric(state, getParentNodeFromPath(state, path, commitNodes, "metricScores"));
    if (!score || !parentScore) {
      continue;
    }

    // Get the conversion factors
    let conversionFactor = getConversionFactor({ score, parentScore, convFactorDetails });
    let parentPath = path.substring(0, path.length - 7);

    let baseMetricId = score.getInPath("metric.uid");
    let _value = getLatestValue(scoreValues.getInPath(`${baseMetricId}.values`)) || Traec.Im.Map();
    let value = _value.get("value");
    let noReport = _value.getInPath("meta_json.noReport");
    //console.log("GOT VALUE FOR PATH", path, baseMetricId, value);

    let parentConversion = get_conversion({ score, value, conversionFactor, noReport });
    let _path = `${parentPath}.${path}`;
    //console.log("SETTING CONVERSION RESULT AT PATH", _path, parentConversion);
    initConversionMap = initConversionMap.setInPath(_path, parentConversion);
  }

  /*console.log("GOT INITAL CONVERSION MAP", initConversionMap.toJS());*/

  store.dispatch(setUIItems(initConversionMap, { itemPath: `childConversions.${commitId}` }));
};

export const getScoreValuesByBaseMetric = (scoreValuesByBaseMetric, baseMetricIds = null) => {
  if (!scoreValuesByBaseMetric) {
    console.log(
      "getScoreValuesByBaseMetric empty map because no scoreValuesByBaseMetric provided" //,
      //Traec.Im.isImmutable(scoreValuesByBaseMetric) ? scoreValuesByBaseMetric.toJS() : scoreValuesByBaseMetric
    );
    return Traec.Im.Map();
  }
  console.log(
    "getScoreValuesByBaseMetric filtering by baseMetricIds" //,
    //Traec.Im.isImmutable(baseMetricIds) ? baseMetricIds.toJS() : baseMetricIds,
    //Traec.Im.isImmutable(scoreValuesByBaseMetric) ? scoreValuesByBaseMetric.toJS() : scoreValuesByBaseMetric
  );
  if (baseMetricIds) {
    scoreValuesByBaseMetric = scoreValuesByBaseMetric.filter((v, baseMetricId) => baseMetricIds.has(baseMetricId));
  }
  return scoreValuesByBaseMetric.map(v => getLatestValue(v.get("values")));
};

export const getChildMetricScores = (path, commitNodes, metricScores) => {
  if (!commitNodes || !metricScores) {
    return Traec.Im.List();
  }
  return (commitNodes.get("byPath") || Traec.Im.Map())
    .filter((value, key) => value.get("type") == "metricscore" && key.startsWith(path))
    .map((i, _path) => metricScores.get(i.get("uid")).set("_path", _path))
    .toList();
};

const getDocumentStatus = (commitNodes, node, documentStatuses) => {
  let _path = (node.getInPath("children.byType.documentstatus") || Traec.Im.List()).first();
  let _id = commitNodes?.getInPath(`byPath.${_path}`)?.get("uid");
  return _id ? documentStatuses?.get(_id) : _id;
};

export const getChildDocumentsWithStatus = (path, commitNodes, documents, documentStatuses) => {
  if (!commitNodes || !documents) {
    return Traec.Im.List();
  }

  return (commitNodes?.get("byPath") || Traec.Im.Map())
    .filter((value, key) => value.get("type") == "document" && key.startsWith(path))
    .map((node, _path) =>
      documents
        .get(node?.get("uid"))
        ?.set("status", getDocumentStatus(commitNodes, node, documentStatuses))
        ?.set("_path", _path)
    )
    .toList();
};

export const pathHasInputs = (path, inputsByPath) => {
  return ((inputsByPath || Traec.Im.Map()).get(path) || Traec.Im.List()).size > 0;
};

const getKeySet = o => {
  return Traec.Im.Set(Traec.Im.isImmutable(o) ? o.keys() : Object.keys(o));
};

const getValue = (o, key) => {
  return Traec.Im.isImmutable(o) ? o.get(key) : o[key];
};

/* Get the diff between Javascript objects i and j - but the values may be immutable
 */
export const hasDiff = (i, j, logPrefix = "") => {
  // Check if the props keys have changed
  let iKeys = getKeySet(i);
  let jKeys = getKeySet(j);

  const logger = logPrefix ? console.log : () => {}

  if (!iKeys.equals(jKeys)) {
    logger(`${logPrefix}:Keys have changed`, iKeys.toJS(), jKeys.toJS());
    return true;
  }

  // Check if immutable structures in the props have changed
  for (let key of iKeys) {
    let _i = getValue(i, key);
    let _j = getValue(j, key);
    if (Traec.Im.isImmutable(_i)) {
      if (_i && !_i.equals(_j)) {
        logger(
          `${logPrefix}:Immutable value has changed`,
          key,
          _i.toJS(),
          Traec.Im.isImmutable(_j) ? _j.toJS() : _j
        );
        return true;
      }
    } else {
      if (_i !== _j) {
        logger(`${logPrefix}:Plain JS value has changed`, key, _i, _j);
        return true;
      }
    }
  }

  return false;
};
