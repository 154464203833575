import React, { useState } from "react";
import Moment from "moment";
import Traec from "traec";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import { projectPermissionCheck } from "traec/utils/permissions/project";
import { alertSuccess } from "traec-react/utils/sweetalert";
import Swal from "sweetalert2";
import { setAndShowModal } from "AppSrc/utils/modal";
import { reportPeriodString } from "AppSrc/project/components";

const setCommitReportingPeriod = (commit, reportPeriodId) => {
  console.log("Setting commit", commit?.toJS(), "to reporting_period", reportPeriodId);

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: commit.get("ref"),
    commitId: commit.get("uid")
  });

  let _id = reportPeriodId || null;
  let _body = {
    reporting_period: _id,
    ...(_id ? {} : { due_date: null })
  };

  fetch.updateFetchParams({
    body: _body,
    postSuccessHook: () => {
      location.reload();
    }
  });

  fetch.dispatch();
};

const editCommitReportingPeriod = ({ projectReportingPeriods, commit, commits }) => {
  let MODAL_ID = "commonReportPeriodModal001";

  let excludeRps = new Traec.Im.Set(
    (commits || Traec.Im.List()).map(i => i.getInPath("reporting_period.uid") || i.get("reporting_period"))
  );

  let options = projectReportingPeriods
    .toList()
    .filter(rp => rp)
    //.filter(rp => !excludeRps.contains(rp.get("uid")))
    .sortBy(i => i.get("startDate"))
    .map((rp, i) => (
      <option key={i} value={rp.get("uid")} disabled={excludeRps.contains(rp.get("uid"))}>
        {reportPeriodString(rp)}
      </option>
    ))
    .unshift(<option key={-1} value={""}></option>);

  let projectId = commit.get("project").substring(0, 8);
  let refId = commit.get("ref").substring(0, 8);
  let rpSettingsUrl = `/project/${projectId}/wpack/${refId}/details`;

  let currentRPId = commit.getInPath("reporting_period.uid") || commit.get("reporting_period");
  console.log("Editing commit Reporting Period", currentRPId);

  setAndShowModal(MODAL_ID, {
    title: "Edit Reporting Period",
    body: (
      <div>
        <select
          className="form-control"
          value={currentRPId || ""}
          onChange={e => setCommitReportingPeriod(commit, e.target.value)}
        >
          {options}
        </select>
        <p>
          You are changing the reporting period for a report already submitted. You cannot select a period that has
          already been submitted or the current reporting period. To change the current reporting period, please go to
          the settings page for this reporting package{" "}
          <a href={rpSettingsUrl} style={{ color: "#007bff" }}>
            here
          </a>
        </p>
      </div>
    )
  });
};

const moveCommitFetch = (trackerId, commitId, toRefId) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  let payload = {
    commit_id: commitId,
    to_ref_id: toRefId
  };

  let formData = new FormData();
  formData.append("type", "MOVE_COMMIT");
  formData.append("payload", JSON.stringify(payload));
  fetch.updateFetchParams({ body: formData });

  fetch.rawFetch().then(response => {
    if (!response.ok) {
      throw response;
    }
    console.log("Report sucessfully moved");
    location.reload();
    return null;
  });
};

function MoveCommitForm({ commitId, trackerId }) {
  let [toRef, setToRef] = useState("");
  return (
    <div>
      <input className="form-control" value={toRef} onChange={e => setToRef(e.target.value)} />
      <p>
        This will move the this report to a different Reporting Package - possibly in a different project or a place you
        don't have access to. Please ensure you would really like to do this.
      </p>
      <button className="btn btn-sm btn-primary" onClick={() => moveCommitFetch(trackerId, commitId, toRef)}>
        Move...
      </button>
    </div>
  );
}

const moveCommit = ({ commit }) => {
  let MODAL_ID = "commonReportPeriodModal001";

  setAndShowModal(MODAL_ID, {
    title: "Move Report",
    body: <MoveCommitForm commitId={commit.get("uid")} trackerId={commit.get("tracker")} />
  });
};

export default class CommitTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null,
      comment: "",
      showForm: false
    };

    this.onInputChange = onInputChange.bind(this);
    this.renderApproveForm = renderApproveForm.bind(this);
    this.patchCommit = patchCommit.bind(this);
  }

  reportUrl() {
    let { commit, projectId, crefId } = this.props;
    let commitId = commit.get("uid");
    let isStaging = commit.get("is_staging");
    let linkStr = isStaging
      ? `/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/report`
      : `/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/report/${commitId.substring(0, 8)}/`;
    return linkStr;
  }

  dropDownLinks() {
    let { userPermission, commit, projectId, projectBaseDisciplineMap, projectDisciplines, crefId } = this.props;
    let { showHistory } = this.state;
    let isProjectAdmin = projectPermissionCheck(projectId, true, []);
    let links = [
      { name: "View", linkTo: this.reportUrl() },
      { name: null },
      {
        name: `${showHistory ? "Hide" : "Show"} History`,
        onClick: e => {
          this.setState({ showHistory: !showHistory });
        }
      }
    ];

    if (isApprover(userPermission, commit, projectId, projectBaseDisciplineMap, projectDisciplines)) {
      links = links.concat([
        { name: null },
        {
          name: "Approve",
          onClick: e => {
              this.setState({ status: "APPROVE", showForm: true });
          }
        },
        {
          name: "Reject",
          onClick: e => {
            this.setState({ status: "REJECT", showForm: true });
          }
        }
      ]);
    }
    if (this.isApportionment()) {
      let reportingPeriodId = commit.getIn(["reporting_period", "uid"]);
      links = links.concat([
        {
          name: "Apportion to My Clients",
          linkTo: `/project/${projectId}/wpack/${crefId}/apportionment/${reportingPeriodId}`
        }
      ]);
    }
    if (isProjectAdmin) {
      links = links.concat([
        {
          name: "Edit Reporting Period",
          onClick: () => {
            editCommitReportingPeriod(this.props);
          }
        },
        {
          name: "Move Report",
          onClick: () => {
            moveCommit(this.props);
          }
        }
      ]);
    }
    return links;
  }

  isApportionment() {
    if (this.props.project.getIn(["meta_json", "apportionment"])) {
      return true;
    } else {
      return false;
    }
  }

  lastUpdateTimeString() {
    let { commit } = this.props;
    let lastUpdate = commit.getInPath("meta_json.lastUpdateOn") || commit.getInPath("created");
    return Moment(lastUpdate).format("Do MMM YY - HH:mm");
  }

  lastUpdateNameString() {
    let { commit } = this.props;
    let lastUpdater = commit.getInPath("meta_json.lastUpdateBy") || commit.get("creator");
    try {
      lastUpdater.get("first_name");
    } catch (e) {
      lastUpdater = commit.get("creator");
    }
    let name = `${lastUpdater.get("first_name")} ${lastUpdater.get("last_name")}`;
    if (!name.trim()) {
      name = lastUpdater.get("email");
    }
    return name;
  }

  getUserName(obj) {
    if (!obj) {
      return "";
    }
    try {
      let name = `${obj.get("first_name")} ${obj.get("last_name")}`;
      if (!name.trim()) {
        name = obj.get("email");
      }
      return name;
    } catch (e) {
      return "";
    }
  }

  renderHistory() {
    if (!this.state.showHistory) {
      return null;
    }
    let { commit } = this.props;
    let history = commit.getInPath("meta_json.history") || Traec.Im.List();

    // Get the titles of this sub-table
    const titles = ["Updated By", "Update Time", "To Status", "Comment"];
    const headCols = titles.map((title, i) => (
      <th key={i} scope="col">
        {title}
      </th>
    ));

    let rows = history.reverse().map((item, i) => {
      return (
        <tr key={i}>
          <td>{this.getUserName(item.get("updateBy"))}</td>
          <td>{Moment(item.get("updateOn")).format("Do MMM YY - HH:mm")}</td>
          <td>{getStatus(item)}</td>
          <td>{item.get("comment")}</td>
        </tr>
      );
    });
    return (
      <tr style={{ borderBottom: "3px solid black", borderTop: "3px solid black" }}>
        <td colSpan={7}>
          <p>
            Status and comment history for report:{" "}
            <b>{reportingPeriodString(this.props.projectReportingPeriods, this.props.commit)}</b>
          </p>
          <table className="table table-sm">
            <thead>
              <tr>{headCols}</tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </td>
      </tr>
    );
  }

  render() {
    let { commit, projectBaseDisciplineMap, hideNullReportingPeriod } = this.props;
    // Get the last updated string
    let isStaging = commit.get("is_staging");
    let baseDisciplineId = commit.getInPath("discipline");
    let disciplineName = projectBaseDisciplineMap.getInPath(`${baseDisciplineId}.name`);
    let commitStatus = getCommitStatus(commit);

    if (isStaging) {
      return null;
    }

    if (commit.get("reporting_period") == null && hideNullReportingPeriod) {
      return null;
    }

    return (
      <React.Fragment>
        <tr>
          {/*<td>{disciplineName || ""}</td>*/}
          <td>{reportingPeriodString(this.props.projectReportingPeriods, this.props.commit) || "undefined"}</td>
          <td>{isStaging ? "Not Submitted" : commitStatus}</td>
          <td>{this.lastUpdateTimeString()}</td>
          <td>{this.lastUpdateNameString()}</td>
          <td>{commit.get("comment")}</td>
          <td>
            <BSBtnDropdown links={this.dropDownLinks()} />
          </td>
        </tr>
        {this.state.showForm ? (
          <tr>
            <td colSpan={7}>{this.renderApproveForm()}</td>
          </tr>
        ) : null}

        {this.renderHistory()}
      </React.Fragment>
    );
  }
}

export const renderApproveForm = function(currentPeriodString = null) {
  if (!this.state.showForm) {
    return null;
  }
  currentPeriodString = currentPeriodString
    ? currentPeriodString
    : reportingPeriodString(this.props.projectReportingPeriods, this.props.commit);
  return (
    <React.Fragment>
      <div>
        <p>
          Changing status to <b>{this.state.status}</b> for reporting period <b>{currentPeriodString}</b>. Please
          provide a comment.
        </p>
        <input
          className="form-control mb-2"
          type="text"
          id="comment"
          name="comment"
          value={this.state.comment}
          onChange={this.onInputChange}
        />
        <button className="btn btn-sm btn-default" onClick={e => this.setState({ showForm: !this.state.showForm })}>
          Close
        </button>
        <button className="btn btn-sm btn-primary float-right" onClick={this.patchCommit}>
          Submit
        </button>
      </div>
    </React.Fragment>
  );
};

export const reportingPeriodString = function(projectReportingPeriods, commit) {
  if (!projectReportingPeriods) {
    return null;
  }
  let reportingPeriod = commit.get("reporting_period");
  let periodString = reportingPeriod
    ? `${Moment(reportingPeriod.get("startDate")).format("Do MMM YY")} to ${Moment(reportingPeriod.get("endDate"))
        .add(-1, "days")
        .format("Do MMM YY")}`
    : null;
  if (!periodString) {
    periodString = " ";
  }
  return periodString;
};

export const patchCommit = function(e) {
  e.preventDefault();
  let { commit, crefId, projectId } = this.props;
  let { comment, status } = this.state;

  if (this.state.comment == "") {
    Swal.fire({
      text: "Please enter a comment before approving or rejecting this report"
    });
  }
  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: crefId,
    commitId: commit.get("uid")
  });
  fetch.updateFetchParams({
    body: { comment, status },
    postSuccessHook: data => {
      alertSuccess({
        text: `Thank you, the reporting package status has been updated`,
        onConfirm: () => {
          location.href = `/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/evals`;
        }
      });
    }
  });
  fetch.dispatch();
};

export const onInputChange = function(e) {
  e.preventDefault();
  this.setState({ [e.target.name]: e.target.value });
};

export const isApprover = function(userPermission, commit, projectId, projectBaseDisciplineMap, projectDisciplines) {
  let isProjectAdmin = projectPermissionCheck(projectId, true, []);
  if (isProjectAdmin) {
    return true;
  }
  let disciplineId = commit.get("discipline");
  let projectDiscipline = projectBaseDisciplineMap.get(disciplineId);
  if (projectDiscipline) {
    let approverId = projectDiscipline.get("approver");
    let approverProjectDiscipline = projectDisciplines.get(approverId);
    if (approverProjectDiscipline) {
      return userPermission.get("projectDisciplineIds").contains(approverId);
    }
  }
  return false;
};

export const getCommitStatus = function(commit) {
  //let commitStatus = commit.getInPath("status.name") || "Pending Approval";
  if (!commit) {
    return null;
  }
  let commitStatus = commit.getInPath("status.name");
  if (!commitStatus) {
    return null;
  }
  if (commitStatus.startsWith("OK")) {
    commitStatus = "Approved";
  }
  return commitStatus;
};

export const getCommitStageStatus = function(commit) {
  //let commitStatus = commit.getInPath("status.name") || "Pending Approval";

  return commit.getInPath("is_staging");
};

export const getStatus = function(item) {
  let status = item.getInPath("status.name");
  if (status === "OK for Submission") {
    return "Approved";
  } else {
    return status;
  }
};
