import React, { useState, useEffect } from "react"
import { useModal, Modal } from "storybook-dashboard/components/modal"
import useApi, { fetcher } from "storybook-dashboard/utils/fetching"
import { BasicSpinner } from "@action.sustainability/storybook-dashboard/spinners/basicSpinner"
import Im from "immutable"
import { alertSuccess } from "traec-react/utils/sweetalert";
import { reportingPeriodText } from "AppSrc/project/report/utils";
import { v4 as uuidv4 } from "uuid";


const isPercent = (indicator) => {
    let name = (indicator?.get("name") || "").toLowerCase()
    return name.includes("%") || name.includes("percent")
}


const isInvalid = (indicator) => {
    return (isPercent(indicator) && indicator.get("result_value") > 100)
}



function IndicatorMetricsList({indicator}) {
    let metrics = indicator?.get("metrics") || Im.List()
    metrics = metrics.filter(metric => metric?.get("value") !== null && metric?.get("value") !== undefined);
    metrics = metrics.sortBy(metric => metric?.get("name"))
    return metrics.map((metric, i) => (<li key={i}>{metric?.get("name")} = {metric?.get("value")?.toFixed(2)}</li>))
}


function ListInvalidIndicators({indicators}) {
    return indicators?.map((indicator, index) => (
        <li key={index}>
            <strong>{index + 1}. {indicator.get("name")} = {indicator.get("result_value")?.toFixed(2)}</strong>
            <ul>
                <IndicatorMetricsList indicator={indicator}/>
            </ul>
        </li>
    )
    )
}


const updateSuccess = ({payload, redirectUrl, originalReport}) => {
    alertSuccess({
        text: `Thank you for submitting your data.  Your report was successfully updated.`,
        onConfirm: () => { location.href = redirectUrl }
    });
}


const submitSuccess = ({payload, redirectUrl, originalReport}) => {
    let reportingPeriodString = reportingPeriodText(originalReport?.get("reporting_period_data"));
    alertSuccess({
        text: `Thank you for submitting your data for ${reportingPeriodString}.  Your report was successfully submitted.`,
        onConfirm: () => { location.href = redirectUrl }
    });
}


const submitError = (err) => {
    alertSuccess({
        text: `There was an error submitting your report`,
        iconType: "error",
    });
}


const submitReport = (e, report, hideModal, setPending, method = "POST") => {
    e.preventDefault()
    setPending(true)
    let { uid: reportId, ref: refId, tracker: trackerId, project: projectId } = (report?.toJS() || {})
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    const url = (method === "POST") 
        ? `/api/tracker/${trackerId}/ref/${refId}/commit/` 
        : `/api/tracker/${trackerId}/ref/${refId}/commit/${reportId}/`

    if (method !== "POST") { data.status = "UPDATE"}

    let redirectUrl = `/project/${projectId}/wpack/${refId}/evals`

    console.log("Submitting report data...", url, method, data)

    fetcher(url, method, data)
        .then(({payload}) => {
            let handler = (method === "POST") ? submitSuccess : updateSuccess
            hideModal()
            handler({payload, redirectUrl, originalReport: report})
        })
        .catch((err) => {
            console.error("submitReport error", err)
            hideModal()
            submitError(err)
        })
}


function SubmitForm({reportId, method}) {
    let [ pending, setPending ] = useState(false)
    let { hideModal } = useModal()
    let {data: report, isLoading} = useApi(`/api/commit/${reportId}/`)

    if (isLoading) { return (<BasicSpinner />) }
    console.log("SubmitModal", method, report?.toJS())

    let placeholder = (report || Im.Map()).getIn(["meta_json", "submit_placeholder"]) || "Write a comment...";
    return (
        <form onSubmit={(e) => submitReport(e, report, hideModal, setPending, method)}>
            <div className="form-group">
                <input name="comment" className="form-control" placeholder={placeholder} />
            </div>
            <button 
                className="btn btn-sm btn-primary"
                type="submit"
                disabled={pending}
            >
                Submit
            </button>
        </form>
    )
}


export function SubmitModal(props) {
    return (
        <Modal title="Submitting report...">
            <SubmitForm {...props}/>
        </Modal>
    )
}


function ClearModal() {
    let { hideModal } = useModal()
    useEffect(() => hideModal(), [])
    return null
}


function ValidateIndicators(props) {
    let { reportId, method } = props
    let [invalidIndicators, setInvalidIndicators] = useState(Im.List())
    let { setModal, hideModal } = useModal()
    // This is a hack to force a single call to the url per instance
    let [ uuid, setUuid ] = useState(`${uuidv4()}`)  
    let { data: indicators, isLoading, isFetching } = useApi(`/api/dashboard/report/${reportId}/indicator?requestId=${uuid}`)

    useEffect(() => {
        if (indicators) {
            console.log("ValidateIndicators got indicators", indicators?.toJS())
            let invalids = indicators?.filter(i => isInvalid(i))
            if (!invalids?.size) {
                setModal((<SubmitModal {...props} />))
            } else {
                setInvalidIndicators(invalids)
            }
        } 
        if (!indicators && !isLoading && !isFetching) {
            setModal((<SubmitModal {...props} />))
        }
    }, [indicators])

    if (isLoading) { return (<BasicSpinner />) }

    // When there are no indicators, users can still submit the report
    if (!indicators && !isLoading && !isFetching) {
        setModal((<SubmitModal {...props} />))
    }

    return (
        <div>
            <p>There's something unusual in your report. Please check the following indicators:</p>
            <ul>
                <ListInvalidIndicators indicators={invalidIndicators}/>
            </ul>
            <div className="text-center">
                <div className="btn-group" role="group">
                    <button className="btn btn-sm btn-danger mr-3" onClick={() => setModal((<SubmitModal {...props} />))}>
                        Ignore and proceed
                    </button>
                    <button className="btn btn-sm btn-success ml-3" onClick={() => setModal((<ClearModal />))}>
                        Go back and edit
                    </button>
                </div>
            </div>
        </div>
    )
}


export function ValidateAndSubmitModal(props) {
    return (
        <Modal title="Validating report..." hideClose={true}>
            <ValidateIndicators {...props} />
        </Modal>
    )
}