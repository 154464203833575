import React, { useState } from "react";
import Traec from "traec";

import CreatableSelect from "react-select/creatable";

import { ProjectPermission } from "traec/utils/permissions/project";
import { Spinner } from "traec-react/utils/entities";
import { categoryNames } from "AppSrc/project/metrics/";
import { alertSuccess } from "traec-react/utils/sweetalert";
import { getMetricsFromIndicator } from "./equations";
import { hasDiff } from "AppSrc/utils";

export const projectFields = {
  name: { value: "", class: "col" },
  category: {
    label: "Sustainability Issue",
    defaultValue: categoryNames[0],
    value: categoryNames[0],
    class: "col",
    endRow: true,
    inputType: "createableSelect",
    options: categoryNames.map((name, i) => ({ label: name, value: name }))
  }
};

const eqnTypes = [
  {
    long: "factor * sum(numerators) / sum(denominators)",
    short: "F * N / D"
  },
  {
    long: "factor * [sum(numerators) - sum(numerators2)] / sum(denominators)",
    short: "F * (N1 - N2) / D"
  },
  {
    long: "factor * [sum(numerators) * sum(numerators2)] / sum(denominators)",
    short: "F * (N1 * N2) / D"
  }
];

function EquationTypeSelect({ equationType, changeHandler }) {
  let eqnTypeText = eqnTypes[equationType].long;

  let eqnOpts = eqnTypes.map((obj, i) => (
    <option key={i} value={i}>
      {obj.short}
    </option>
  ));

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-9">
          <p>Equation of the form:</p>
          <p>
            <b>{eqnTypeText}</b>
          </p>
        </div>
        <div className="col-sm-3">
          <div className="form-group col">
            <label htmlFor="eqnTypeSelect">Equation Type</label>
            <select className={`custom-select`} onChange={changeHandler} name="eqnType" value={equationType}>
              {eqnOpts}
            </select>
          </div>
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
}

function EquationRender(props) {
  console.log("RENDERING EquationRender");
  let {
    equationType,
    name,
    numerators,
    numerators2,
    denominators,
    factor,
    category,
    toggleShowHandler,
    saveHandler,
    changeHandler,
    multiSelectChangeHandler,
    baseMetrics
  } = props;

  let bmSelectProps = { baseMetrics, changeHandler, multiSelectChangeHandler };
  return (
    <form>
      <div className="row">
        <InputField name="name" label="Name" value={name} changeHandler={changeHandler} />
        <CategorySelect selectedCategory={category} changeHandler={changeHandler} />
        <div className="w-100" />
        <InputField name="factor" label="Factor" value={factor} type="number" changeHandler={changeHandler} />
        <BaseMetricSelect {...bmSelectProps} title="Numerators" name="numerators" value={numerators} />
        {equationType > 0 ? (
          <BaseMetricSelect {...bmSelectProps} title="Numerators2" name="numerators2" value={numerators2} />
        ) : null}
        <BaseMetricSelect {...bmSelectProps} title="Denominators" name="denominators" value={denominators} />
        <div className="w-100" />
      </div>
      <button className="btn btn-sm btn-default" onClick={toggleShowHandler}>
        Close
      </button>
      <button className="btn btn-sm btn-primary float-right" onClick={saveHandler}>
        Save
      </button>
      <div style={{ clear: "both" }} />
    </form>
  );
}

function CategorySelect({ selectedCategory, changeHandler }) {
  let options = categoryNames.map((name, i) => ({ label: name, value: name }));
  return (
    <div className="form-group col">
      <label htmlFor="categorySelect">Sustainability Issue</label>
      <CreatableSelect
        isClearable
        placeholder="Start typing to select or create..."
        onChange={data => {
          console.log("Handling change to value", data);
          changeHandler({
            preventDefault: () => null,
            target: {
              name: "category",
              value: data.value
            }
          });
        }}
        options={options}
        defaultValue={((options || []).filter(i => i.value == selectedCategory) || [])[0]}
      />
    </div>
  );
}

function InputField({ name, label, value, changeHandler, type = "text" }) {
  return (
    <div className="form-group col">
      <label htmlFor="nameInput">{label}</label>
      <input className="form-control" type={type} name={name} value={value} onChange={changeHandler} id="nameInput" />
    </div>
  );
}

class BaseMetricSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputIds: "",
      errMsg: ""
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return hasDiff(nextProps, this.props) || hasDiff(nextState, this.state);
  }

  render() {
    let { baseMetrics, title, name, value, changeHandler, multiSelectChangeHandler } = this.props;
    let { inputIds, errMsg } = this.state;
    console.log("RENDERING BaseMetricSelect", baseMetrics.hashCode(), title, name, value);

    let selectedValues = value || [];

    // Get the ids of the selected metrics we print at the bottom
    //console.log("SELECTED VALUES", selectedValues)
    let selectedIds = selectedValues.map(i => i.substring(0, 8)).join(", ");

    let validBaseMetrics = baseMetrics
      .toList()
      .filter(bm => bm && bm.get("uid") && bm.get("unit") != "calculated")
      .sortBy(bm => bm.get("category"));

    return (
      <div className="form-group col">
        <label>{title}</label>
        <select
          multiple={true}
          className={`custom-select`}
          onChange={multiSelectChangeHandler}
          name={name}
          value={selectedValues}
          //defaultValue={selectedValues}
        >
          {validBaseMetrics.map((bm, i) => (
            <option key={i} value={bm.get("uid")}>
              {bm.get("category")} : {bm.get("name")}
            </option>
          ))}
        </select>
        <div className="row">
          <div className="col">
            <input
              className="form-control form-control-sm mt-1"
              style={{ height: "1.6rem" }}
              value={inputIds}
              onChange={e =>
                this.setState({
                  errMsg: "",
                  inputIds: e.target.value
                })
              }
            />
          </div>
          <div className="w-20">
            <button
              className="btn btn-sm btn-secondary m-1 pl-2 pr-2 p-0"
              onClick={e => {
                e.preventDefault();
                let _ids = new Set(inputIds.split(",").map(i => i.trim()));
                let baseMetricIds = validBaseMetrics
                  .filter(bm => _ids.has(bm.get("uid").substring(0, 8)) || _ids.has(bm.get("uid").substring(0, 8)))
                  .map(bm => bm.get("uid"))
                  .toJS();
                if (!baseMetricIds) {
                  this.setState({ errMsg: "Error: Metric not found from available in list. Try again." });
                }
                let uniqueIds = new Set([...selectedValues, ...baseMetricIds]);
                changeHandler({
                  target: {
                    name,
                    value: [...uniqueIds]
                  },
                  preventDefault: e.preventDefault
                });
              }}
            >
              Add by Id
            </button>
          </div>
        </div>
        {errMsg ? (
          <small className="text-danger">
            {errMsg}
            <br />
          </small>
        ) : null}
        <small>Selected: {selectedIds}</small>
      </div>
    );
  }
}

export class CreateIndicatorForm extends React.Component {
  constructor(props) {
    super(props);

    let {
      indicator,
      name,
      category,
      factor,
      baseMetrics,
      denominators,
      numerators,
      numerators2,
      dispatchType,
      eqnType
    } = props;

    indicator = Traec.Im.fromJS(indicator);
    baseMetrics = Traec.Im.fromJS(baseMetrics);
    if (indicator && dispatchType == "put") {
      console.log("Editing indicator", indicator.toJS());
      // Get the metrics and operations from the indicator
      let { factor: _factor, ops: _ops } = getMetricsFromIndicator({ indicator, baseMetrics });
      _ops = Traec.Im.isImmutable(_ops) ? _ops.toJS() : _ops;

      // Get just the operations with metricId as arguments
      let __ops = _ops.filter(o => o.operation == "sum" && o.args && !o.args.some(i => typeof i !== "string"));
      console.log("Got flattened indicator operations", __ops);

      // Get some properties from the
      name = indicator.getInPath("resultBaseMetric.name");
      category = indicator.getInPath("resultBaseMetric.category");
      factor = parseFloat(_factor);
      if (__ops.length > 2) {
        eqnType = 1;
        denominators = __ops[2] ? __ops[2].metricIds : [];
        numerators = __ops[0] ? __ops[0].metricIds : [];
        numerators2 = __ops[1] ? __ops[1].metricIds : [];
      } else {
        eqnType = 0;
        denominators = __ops[1] ? __ops[1].metricIds : [];
        numerators = __ops[0] ? __ops[0].metricIds : [];
      }
      //
      console.log(
        " - Setting initial indicator properties",
        _factor,
        _ops.length,
        _ops,
        denominators,
        numerators,
        numerators2
      );
    }

    this.state = {
      eqnType: eqnType || 0,
      name: name || "",
      category: category || "",
      numerators: [],
      numerators2: [],
      denominators: [],
      factor: factor || 1,
      numerators2: numerators2 || null,
      numerators: numerators || null,
      denominators: denominators || null,
      dispatchType: dispatchType || "post"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.putOrPostIndicator = this.putOrPostIndicator.bind(this);
  }

  eqn0_text() {
    // Construct the equation that we will post here
    let { numerators, denominators, factor } = this.state;
    let denIds = (denominators || []).join(",");
    let numIds = (numerators || []).join(",");
    let childOp = denIds ? `1 * [ sum( sum(${denIds}), sum() ) ] ^ -1` : "";
    let parentOp = `${factor} * [ mult( sum(${numIds}), sum(${childOp}) ) ] ^ 1`;
    return parentOp;
  }

  eqn0_json() {
    let { numerators, denominators, factor } = this.state;
    return {
      operation: "mult",
      args: [
        factor,
        {
          operation: "div",
          args: [
            {
              name: "N",
              operation: "sum",
              args: numerators || []
            },
            {
              name: "D",
              operation: "sum",
              args: denominators || []
            }
          ]
        }
      ]
    };
  }

  eqn1_text() {
    // Construct the equation that we will post here
    let { numerators, numerators2, denominators, factor } = this.state;
    let denIds = (denominators || []).join(",");
    let numIds = (numerators || []).join(",");
    let numIds2 = (numerators2 || []).join(",");
    let childOp1 = numIds ? `1 * [ sum( sum(${numIds}), sum() ) ] ^ 1` : "";
    let childOp2 = numIds2 ? `-1 * [ sum( sum(${numIds2}), sum() ) ] ^ 1` : "";
    let numOps = `1 * [ sum( sum(), sum(${[childOp1, childOp2].join(",")}) ) ] ^ 1`;
    let denOp = denIds ? `1 * [ sum( sum(${denIds}), sum() ) ] ^ -1` : "";
    let parentOp = `${factor} * [ sum( sum(), mult(${[numOps, denOp].join(",")}) ) ] ^ 1`;
    return parentOp;
  }

  eqn1_json() {
    let { numerators, numerators2, denominators, factor } = this.state;
    return {
      operation: "mult",
      args: [
        factor,
        {
          operation: "div",
          args: [
            {
              operation: "sum",
              args: [
                {
                  name: "N1",
                  operation: "sum",
                  args: numerators || []
                },
                {
                  operation: "mult",
                  args: [
                    -1,
                    {
                      name: "N2",
                      operation: "sum",
                      args: numerators2 || []
                    }
                  ]
                }
              ]
            },
            {
              name: "D",
              operation: "sum",
              args: denominators || []
            }
          ]
        }
      ]
    };
  }

  eqn2_text() {
    return "";
  }

  eqn2_json() {
    let { numerators, numerators2, denominators, factor } = this.state;
    return {
      operation: "mult",
      args: [
        factor,
        {
          operation: "div",
          args: [
            {
              operation: "mult",
              args: [
                {
                  name: "N1",
                  operation: "sum",
                  args: numerators || []
                },
                {
                  name: "N2",
                  operation: "sum",
                  args: numerators2 || []
                }
              ]
            },
            {
              name: "D",
              operation: "sum",
              args: denominators || []
            }
          ]
        }
      ]
    };
  }

  putOrPostIndicator(e) {
    e.preventDefault();
    let { trackerId, commitId, indicator, fetchHandler, modalId } = this.props;
    let { eqnType, name, category } = this.state;
    let text = null;
    let _json = null;

    indicator = Traec.Im.fromJS(indicator);
    let indicatorId = indicator ? indicator.get("uid") : null;

    if (eqnType == 0) {
      text = this.eqn0_text();
      _json = this.eqn0_json();
    } else if (eqnType == 1) {
      text = this.eqn1_text();
      _json = this.eqn1_json();
    } else if (eqnType == 2) {
      text = this.eqn2_text();
      _json = this.eqn2_json();
    } else {
      alert("Must select an equation type 0, 1 or 2");
    }

    // Post the result
    let method = this.state.dispatchType;
    fetchHandler = fetchHandler || "tracker_commit_indicator";
    let fetch = new Traec.Fetch(fetchHandler, method, { trackerId, commitId, indicatorId });
    fetch.updateFetchParams({
      body: {
        name,
        category,
        operation_json: _json,
        //operation: { text },
        apply_ref_all: true
      },
      postSuccessHook: data => {
        console.log("putOrPostIndicator has success data", data);
        // Hide the form
        this.props.toggleShowHandler({ preventDefault: () => ({}) });
        if (modalId) {
          $(modalId).modal("hide");
        }
        // Show an alert if new metrics were not created
        if (!data.new_metric_calc) {
          alertSuccess({
            title: "Existing Indicator Found and Used",
            text: `An existing indicator was found for the metric you have specified based on name, units and issue.  
                            This existing indicator has been added which may have different metrics from what you have specified.  
                            If you would like to create the metric you have specified then please use a different value for name, units and/or issue`,
            iconType: "warning"
          });
        } else if (!data.new_edge) {
          alertSuccess({
            title: "Already exists",
            text: `An existing indicator metric you have specified based on name, units and issue was already found.
                        If you would like to create the metric you have specified then please use a different value for name, units and/or issue`,
            iconType: "warning"
          });
        }
      }
    });

    fetch.dispatch();
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  handleMultiSelectChange(e) {
    e.preventDefault();
    let values = Object.values(e.target.options)
      .filter(i => i.selected)
      .map(i => i.value);
    this.setState({ [e.target.name]: values });
  }

  render_loading() {
    return <Spinner title="Loading..." explanation="" />;
  }

  render() {
    console.log("RENDERING CreateIndicatorForm");
    let { projectId, baseMetrics, noProject } = this.props;

    let content = (
      <div className="col-sm-12">
        <EquationTypeSelect equationType={this.state.eqnType} changeHandler={this.handleChange} />
        <EquationRender
          {...this.state}
          baseMetrics={baseMetrics}
          equationType={this.state.eqnType}
          saveHandler={this.putOrPostIndicator}
          toggleShowHandler={this.props.toggleShowHandler}
          changeHandler={this.handleChange}
          multiSelectChangeHandler={this.handleMultiSelectChange}
        />
        <hr />
      </div>
    );

    if (noProject) {
      return content;
    }

    return (
      <ProjectPermission projectId={projectId} requiresAdmin={true}>
        {content}
      </ProjectPermission>
    );
  }
}
