import React, { useState } from "react";
import { connect } from "react-redux";

import Traec from "traec";
import { getPathChildren } from "traec/utils/nodes";

import { ErrorBoundary } from "traec-react/errors";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected, { BaseForm } from "traec-react/utils/form";
import { DropzoneButton } from "traec-react/utils/documentUpload/dropZone";
import { Spinner } from "traec-react/utils/entities";

import { getProjectProps, BreadCrumb } from "AppSrc/project/utils";
import MetricCategories from "./metricCategories";

import { setAndShowModal } from "AppSrc/utils/modal";

import Swal from "sweetalert2";
import { alertSuccess } from "traec-react/utils/sweetalert";

export const categoryNames = [
  "Air Quality",
  "Awards",
  "Biodiversity",
  "BREEAM",
  "Carbon",
  "Climate Change",
  "Community",
  "Community Wellbeing",
  "Diversity & Equality",
  "Economic Prosperity",
  "EDI",
  "Employee Engagement",
  "Employee Relations",
  "Employees",
  "EMS",
  "Equality, Diversity & Inclusion",
  "Ethics",
  "Future of Work",
  "Governance",
  "Governance & Safety",
  "Health & Safety",
  "Materials",
  "People Wellbeing",
  "Process & Productivity",
  "Procurement",
  "Quality",
  "Social Value",
  "Supply Chain",
  "Waste",
  "Water",
  //"Traction",
  //"Non-traction",
  "Project Attributes",
  "Other"
];

export const metricCounters = {
  row: 0
};

const setCommitMeta = (cref, meta_json) => {
  if (!cref) {
    return null;
  }
  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: cref.getInPath("tracker"),
    refId: cref.get("uid"),
    commitId: cref.getInPath("latest_commit.uid")
  });
  fetch.updateFetchParams({
    body: {
      meta_json
    },
    postSuccessHook: e => location.reload()
  });
  fetch.dispatch();
};

function SetReportLayout({ cref }) {
  if (!cref) {
    return null;
  }
  let current = cref.getInPath("latest_commit.meta_json.report_layout") || "classic";
  let options = {
    tabbed: "Tabbed layout",
    classic: "Classic layout"
  };
  let _options = Object.entries(options).map(([key, value], i) => (
    <option key={i} value={key}>
      {value}
    </option>
  ));
  return (
    <form className="float-left">
      <select
        value={current}
        className="form-control form-control-sm"
        onChange={e => {
          e.preventDefault();
          setCommitMeta(cref, { report_layout: e.target.value });
        }}
      >
        {_options}
      </select>
    </form>
  );
}

export const SetCommitMeta = props => {
  let { cref, isRootRef } = props;

  if (!cref || isRootRef) {
    return null;
  }

  let placeholder = cref.getInPath("latest_commit.meta_json.submit_placeholder");
  let disableManualEntry = cref.getInPath("latest_commit.meta_json.disable_manual_entry");

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: cref.getInPath("tracker"),
    refId: cref.get("uid"),
    commitId: cref.getInPath("latest_commit.uid")
  });
  fetch.updateFetchParams({
    preFetchHook: body => {
      if (body.disable_manual_entry === undefined) {
        body.disable_manual_entry = false;
      }
      return {
        meta_json: body
      };
    },
    postSuccessHook: e => location.reload()
  });

  return (
    <BaseFormConnected
      params={fetch.params}
      forceShowForm={true}
      submitBtnText={"Save Report Meta Information"}
      fields={{
        submit_placeholder: {
          label: "Set Report submit comment placeholder text",
          value: placeholder,
          class: "col-sm-12",
          placeholder: "Write a comment...",
          endRow: true
        },
        disable_manual_entry: {
          label: "Disable Manual Entry for Calculated Metrics",
          value: disableManualEntry,
          inputType: "checkbox"
        }
      }}
    />
  );
};

const addCategory = ({ trackerId, crefId, commitId, allowedCategoryNames: names }) => {
  let modalId = "CommonMetricSetupModal001";
  let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, refId: crefId, commitId });

  let categoryFields = {
    name: {
      value: names ? names[0] : "", // Select the first name by default
      class: "col",
      inputType: "createableSelect",
      endRow: true,
      options: names.map((name, i) => ({ label: name, value: name }))
    }
  };

  fetch.updateFetchParams({
    preFetchHook: data => {
      return {
        //path: pathId,  // Not specifying a path will put it under the root tree
        type: "tree",
        node: {
          tree: data
        }
      };
    },
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    }
  });

  setAndShowModal(modalId, {
    title: "Add an Issue",
    body: <BaseFormConnected params={fetch.params} fields={categoryFields} forceShowForm={true} hideUnderline={true} />
  });
};

function SetupMenus({ commitNodes, cref, dropDownLinks }) {
  if (!commitNodes) {
    return null;
  }
  return (
    <React.Fragment>
      <ErrorBoundary>
        <SetReportLayout cref={cref} />
      </ErrorBoundary>

      <BSBtnDropdown links={dropDownLinks} header={<span>Set up Metrics</span>} />
      <div style={{ clear: "both" }} />
    </React.Fragment>
  );
}

const postCSVFile = ({ selectedFiles, trackerId, crefId: refId }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });
  //
  let formData = new FormData();
  formData.append("fileobj", selectedFiles[0]);
  formData.append("type", "METRICS_FROM_CSV");
  formData.append("payload", JSON.stringify({ refId }));
  fetch.updateFetchParams({ body: formData });

  Swal.queue([
    {
      title: "Bulk-creation of metrics from CSV",
      confirmButtonText: "Start",
      html:
        "<p>The CSV file you have selected will try to be parsed and metrics created.</p>  <p>Existing metrics will not be affected.  This is an append-only operation.</p>  <p>This may take a while if you have many metrics.  Please be patient.</p>",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch
          .rawFetch()
          .then(response => response.json())
          .then(data => {
            Swal.insertQueueStep({
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              title: "Metrics created successfully",
              html: "<p>The page will now reload</p>",
              confirmButtonText: "Done",
              onClose: () => {
                location.reload();
              }
            });
          })
          .catch(err => {
            Swal.insertQueueStep({
              type: "error",
              title: "Error",
              text: "There was an error generating your report.  Please contact support if the problem persists.",
              onClose: () => {
                location.reload();
              }
            });
          });
      }
    }
  ]);
};

function SelectCSVFile(props) {
  let [selectedFiles, setSelectedFiles] = useState([]);

  let files = selectedFiles.map(file => (
    <a key={file.name}>
      Upload {file.name}? ({(file.size / 1e6).toFixed(1)}Mb)
    </a>
  ));

  // Give a warning if the file is too large
  let confirmButton = null;
  if (selectedFiles.filter(file => file.size / 1e6 > 500).length) {
    files = [
      <span key={0} className="alert-danger">
        Maximum allowed upload size is 500Mb
      </span>
    ];
  } else {
    confirmButton = (
      <BSBtn
        text={"Upload"}
        onClick={e => postCSVFile({ ...props, selectedFiles })}
        extra_className="pl-1 pr-1 m-0 p-0"
        noFloatRight={true}
      />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="float-right">
          <DropzoneButton
            onDrop={files => setSelectedFiles(files)}
            extra_className="pl-1 pr-1 m-0 p-0"
            selectAreaText="Select file"
            confirmButton={confirmButton}
            selectedFiles={files}
            onCancelUpload={() => setSelectedFiles([])}
          />
        </div>
      </div>
    </div>
  );
}

const showFileSelect = props => {
  let { modalId } = props;
  setAndShowModal(modalId, {
    title: "Create from CSV file",
    body: <SelectCSVFile {...props} />
  });
};

const postCommit = (e, params) => {
  e.preventDefault();
  let { trackerId, crefId, commitId } = params;
  let fetch = new Traec.Fetch("tracker_ref_commit", "post", { trackerId, refId: crefId, commitId });
  fetch.updateFetchParams({
    body: {
      comment: `Saved metric tree state ${new Date()}`,
      recurse: false
    }
  });
  fetch.dispatch();
};

const importFromJSON = ({ target, trackerId, commitId }) => {
  let file = target.files[0];
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  if (file) {
    let formData = new FormData();
    formData.append("fileobj", file);
    formData.append("type", "IMPORT_FROM_JSON");
    formData.append("payload", JSON.stringify({ commitId }));
    fetch.updateFetchParams({ body: formData });

    fetch.updateFetchParams({
      body: formData,
      postSuccessHook: () => {
        alertSuccess({
          text: `JSON file uploaded`,
          onConfirm: () => {
            location.reload();
          }
        });
      }
    });

    fetch.dispatch();
  }
};

export const exportToCSV = ({ trackerId, commitId }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  let formData = new FormData();
  formData.append("type", "METRICS_TO_CSV");
  formData.append("payload", JSON.stringify({ commitId }));
  fetch.updateFetchParams({ body: formData });

  Swal.queue([
    {
      title: "Export",
      confirmButtonText: "Generate CSV file",
      html: "<p>This may take a minute. Please be patient.</p>",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch
          .rawFetch()
          .then(response => response.blob())
          .then(data => {
            console.log("GOT REPORT DATA", data);
            let blobUrl = window.URL.createObjectURL(data);
            Swal.insertQueueStep({
              showConfirmButton: false,
              showCancelButton: false,
              title: "Download ready",
              html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" download="report_${commitId.substring(
                0,
                8
              )}_metrics.csv">Download</a>`
            });
          })
          .catch(err => {
            Swal.insertQueueStep({
              type: "error",
              title: "Error",
              text: "There was an error generating your report.  Please contact support if the problem persists."
            });
          });
      }
    }
  ]);
};

class ProjectMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedUrls: {},
      showDocs: false
    };

    this.requiredFetches = [
      new Traec.Fetch("project_tracker", "list"),
      new Traec.Fetch("tracker_node", "list"),
      new Traec.Fetch("tracker_ref_commit", "list")
    ];
  }

  /**********************
    COMPONENT METHODS
  **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  getUrlParams() {
    const { crefId, rootTree, trackerId, commitId } = this.props;
    const rootTreeId = rootTree ? rootTree.get("uid") : null;
    return { crefId, commitId, trackerId, rootTreeId };
  }

  /**********************
    MENU METHODS
    **********************/

  dropDownLinks() {
    let { projectId, crefId, cref, sortKey, allowedCategoryNames, trackerId, commitId, history } = this.props;
    let urlParams = this.getUrlParams();
    let thisItems = [
      { name: "Add Sustainability Issue", onClick: e => addCategory({ ...urlParams, allowedCategoryNames }) },
      {},
      { name: "Add from Template", linkTo: `${this.props.match.url}/add_from_template` },
      {
        name: "Add from CSV file",
        onClick: () => showFileSelect({ ...this.props, ...urlParams })
      },
      {},
      {
        name: sortKey === "_path" ? "Sort alphabetically" : "Sort by path",
        onClick: () => {
          setCommitMeta(cref, { sortKey: sortKey === "_path" ? "name" : "_path" });
        }
      },
      {},
      {
        name: "View as Report",
        onClick: () => {
          let reportUrl = `/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/report`;
          window.location.assign(reportUrl);
        }
      },
      {},
      {
        name: "Export to CSV",
        onClick: () => {
          exportToCSV({ trackerId, commitId });
        }
      },
      {
        name: "Export to JSON",
        onClick: () => {
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = `/api-docs/auth/login/?next=/api/tracker/${trackerId}/commit/${commitId}/node/?format=json`;
          //link.download = `${trackerId.substring(0, 8)}_${commitId.substring(0, 8)}.json`;
          link.target = "_blank";
          link.rel = "noreferrer noopener";
          document.body.appendChild(link);
          link.click();
        }
      },
      {
        name: "Import from JSON",
        onClick: () =>
          setAndShowModal("IMPORT_JSON", {
            title: `Import from JSON File`,
            body: (
              <>
                <div className="row text-center">
                  <div className="container-fluid">
                    <p className="text-center">Import from JSON file</p>
                    <label className="btn btn-sm btn-secondary mt-2 py-0 pointer" style={{ height: "31px" }}>
                      Select new File
                      <input
                        style={{ visibility: "hidden", height: "0px", width: "0px" }}
                        type="file"
                        onChange={event => importFromJSON({ target: event.target, trackerId, commitId })}
                      />
                    </label>
                  </div>
                </div>
              </>
            )
          })
      }
      //,
      //{ name: "Save State", onClick: e => postCommit(e, urlParams) }
    ];
    return thisItems;
  }

  render() {
    const { company, cref, project, isRootRef, tracker, commitId, rootTreeId, rootTreePath, sortKey } = this.props;
    if (!project || !tracker) {
      return null;
    }
    metricCounters.row = 0;
    return (
      <React.Fragment>
        <h3>Project Metrics</h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />

        {/* Button for adding top-level tree objects */}
        <ErrorBoundary>
          <SetupMenus {...this.props} dropDownLinks={this.dropDownLinks()} />
        </ErrorBoundary>

        {/* Render the categories and row */}
        <ErrorBoundary>
          <MetricCategories
            cref={cref}
            commitId={commitId}
            rootTreeId={rootTreeId}
            rootTreePath={rootTreePath}
            sortKey={sortKey}
          />
        </ErrorBoundary>

        <hr />
        <ErrorBoundary>
          <SetCommitMeta cref={cref} isRootRef={isRootRef} />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { _commitId } = ownProps.match.params;
  let { projectId, refId, commitId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, isRootRef } = getProjectProps(state, projectId, refId);

  // Get the latest (working) commit
  let commit = _commitId
    ? state.getInPath(`entities.commits.byId.${commitId}`)
    : cref
    ? cref.get("latest_commit")
    : null;
  commitId = commit?.get("uid");

  // Get the root tree to start with
  let rootTree = commit ? commit.get("tree_root") : null;
  let rootTreeId = rootTree ? rootTree.get("uid") : null;

  // Get all of the trees that are children of the rootTree (edges)
  let commitNodes = commitId ? state.getInPath(`entities.commitNodes.${commitId}`) : null;

  // Adjust the category selection form (to avoid adding categories twice)
  let allowedCategoryNames = categoryNames;
  let pathRoot = null;
  if (commitNodes) {
    pathRoot = commitNodes.get("pathRoot");
    let subTrees = getPathChildren(state, pathRoot, commitNodes, "trees").filter(i => i);
    let subTreeNames = new Set(subTrees.map(tree => tree.get("name")));
    allowedCategoryNames = categoryNames.filter(s => !subTreeNames.has(s));
  }

  // sort alphabetically by default
  let sortKey = commit?.getInPath("meta_json.sortKey") || "name";

  // Add this to props
  return {
    tracker,
    trackerId,
    projectId,
    project,
    company,
    cref,
    crefId,
    refId: crefId,
    isRootRef,
    commitId,
    rootTree,
    rootTreeId,
    rootTreePath: pathRoot,
    commitNodes,
    allowedCategoryNames,
    sortKey
  };
};

export default connect(mapStateToProps)(ProjectMetrics);
