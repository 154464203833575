import React from "react";
import { connect } from "react-redux";
import Traec from "traec";
import BaseFormConnected from "traec-react/utils/form";

import { metricFields, documentFields } from "./forms";
import ReportScoreRow from "./reportMetricRow";
import ReportDocumentRow from "./reportDocumentRow";

import { getNodeFromPath, getPathChildren } from "traec/utils/nodes";
import { SubNodes } from "./node";

class ReportTreeRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Form settings
      metricFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {}
      },
      docFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {}
      },
      // state object for handling conversion from child-metrics
      // to metrics at this level.  This gets passed to metric score objects
      // at this level to generate their value (if the metric is set as "calculated").
      conversions: Traec.Im.Map(),
      // Render methods
      collapsed: false,
      showDocs: false
    };

    this.addMetric = this.addMetric.bind(this);
    this.toggleShowDocs = this.toggleShowDocs.bind(this);
    this.toggleCollapsedState = this.toggleCollapsedState.bind(this);
    this.childConversionHandler = this.childConversionHandler.bind(this);
  }

  /**********************
    DROPDOWN MENUS 
    **********************/

  dropDownLinks() {
    let thisItems = [
      { name: "Add Sub-Metric", onClick: this.addMetric },
      { name: "Add Document", onClick: this.addDocument },
      { name: "Rename", onClick: null },
      { name: null },
      { name: `${this.state.showDocs ? "Hide" : "Show"} Documents`, onClick: this.toggleShowDocs },
      { name: null },
      { name: "Delete", onClick: this.deleteTree }
    ];
    let extraDropDowns = this.props.extraDropDowns || [];
    return extraDropDowns.concat(thisItems);
  }

  /**********************
    MENU OPERATIONS 
    **********************/

  toggleCollapsedState(e) {
    e.preventDefault();
    //this.setState({collapsed: !this.state.collapsed})
  }

  getCollapsedState(id) {
    let savedValue = localStorage.getItem(id);
    return savedValue ? savedValue == "true" : this.state.collapsed;
  }

  toggleShowDocs(e) {
    e.preventDefault();
    this.setState({ showDocs: !this.state.showDocs });
  }

  addMetric(e) {
    e.preventDefault();
    let { rootTree } = this.props;
    let rootTreePath = rootTree.get("path");
    let { trackerId, refId, commitId, rootTreeId: treeId } = this.getUrlParams();
    let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, refId, commitId });
    fetch.updateFetchParams({
      preFetchHook: data => {
        if (!data.category) {
          data.category = this.props.categoryName;
        }
        return data;
      }
    });
    this.setState({ metricFormParams: fetch.params });
    fetch.toggleForm();
  }

  addDocument(e) {
    e.preventDefault();
    let { trackerId, refId, commitId, rootTreeId: treeId } = this.getUrlParams();
    let fetch = new Traec.Fetch("tracker_ref_tree_document", "post", { trackerId, refId, commitId, treeId });
    this.setState({ docFormParams: fetch.params });
    fetch.toggleForm();
  }

  getUrlParams() {
    const { cref, rootTreeId } = this.props;
    if (!cref) {
      return {};
    }

    const trackerId = cref.get("tracker");
    const commitId = cref.get("latest_commit").get("uid");
    const refId = cref.get("uid");
    return { refId, commitId, trackerId, rootTreeId };
  }

  /**********************
    CHILD METRIC CONVERSIONS 
    **********************/

  childConversionHandler(conversionArray) {
    let newState = this.state.conversions;
    for (let conversion of conversionArray) {
      let { fromMetric, toUnit } = conversion;
      let fromMetricId = fromMetric.get("uid");
      newState = newState.setIn([toUnit, fromMetricId], Traec.Im.fromJS(conversion));
    }
    this.setState({ conversions: newState });
  }

  render() {
    let { tree } = this.props;
    return (
      <React.Fragment>
        {/*<tr>
          <td colSpan="100%">{tree ? tree.get("name") : "undefined"}</td>
        </tr>*/}
        <SubNodes {...this.props} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { path, commitNodes, cref } = ownProps;
  let tree = getNodeFromPath(state, path, commitNodes);

  // Get the sort for metrics below this level
  let commit = cref?.get("latest_commit");
  let sortKey = commit?.getInPath("meta_json.sortKey") || "metric.name";
  sortKey = sortKey == "name" ? "metric.name" : sortKey;

  return { tree, sortKey };
};

const ReportTreeRowConnected = connect(mapStateToProps)(ReportTreeRow);
export default ReportTreeRowConnected;
