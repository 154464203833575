import Dropzone from "react-dropzone";
import React, { useState } from "react";

// Description:
// This component is an upgrade of the Traec - react DropzoneButton.This version uses React hooks and renders a spinner when a user selects a file to upload until that file is processed by the browser.
// How to use:
// 1. create a loading state in the parent component
// 2. create 2 functions, 1 will set the loading state to true the other to false
// 3. create a files state
// 4. create an ondrop function which takes 'files' as an argument and sets the files state to the files argument

export const DropzoneButton = props => {
  function onCancel() {
    props.stopIsUploadingFile();
  }

  function onOpen() {
    props.startIsUploadingFile();
  }

  let selectAreaText = props.selectAreaText || "Click here to select a file";

  return (
    <div>
      <Dropzone onDrop={props.onDrop} onFileDialogCancel={onCancel} onFileDialogOpen={onOpen}>
        {({ getRootProps, getInputProps }) => {
          if (props.selectedFiles.length) {
            return (
              <React.Fragment>
                {props.selectedFiles} {props.confirmButton} |
                <button className="btn btn-sm btn-default pl-1 pr-1 m-0 p-0" onClick={props.onCancelUpload}>
                  Clear
                </button>
              </React.Fragment>
            );
          }
          if (props.selectedFiles < 1 && props.isUploadingFile) {
            return (
              <>
                <div className="spinner-border px-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </>
            );
          }
          return (
            <div
              {...getRootProps()}
              className={`btn btn-sm btn-secondary ${props.extra_className} ${
                props.isUploadingFile ? " disabled" : ""
              }`}
            >
              <input {...getInputProps()} />
              {selectAreaText}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};
