import React, { useState } from "react";
import { BSBtn, BSBtnDropdown, BSModal } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors";
import ReportRowErrorBoundary from "./error";
import { DocumentTitleTooltip, CurrentObject, DocumentNoReportCheckbox } from "./reportDocumentRow";
import Traec from "traec";
import Papa from "papaparse";
import { alertSuccess, confirmDelete } from "traec-react/utils/sweetalert";
import Moment from "moment";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";
import { DataTable } from "./dataTable";

const fetchS3Object = ({ trackerId, commitId, docId, currentDocObject, responseHandler, failureHandler }) => {
  let _fetch = new Traec.Fetch("tracker_commit_document_object", "retrieve", {
    trackerId,
    commitId,
    docId,
    docObjectId: currentDocObject.get("uid"),
    signedURL: true
  });
  _fetch.updateFetchParams({
    throttleTimeCheck: 1,
    cacheTime: 1,
    postSuccessHook: data => {
      if (data.signed_url) {
        fetch(data.signed_url, { cache: "no-cache" }).then(responseHandler);
      }
    },
    postFailureHook: err => {
      failureHandler ? failureHandler(err) : null;
    }
  });
  _fetch.dispatch();
};

function LoadDataButton(props) {
  let { title, hide } = props;
  if (hide) {
    return null;
  }
  return (
    <button
      className="btn btn-sm btn-warning pt-0 pb-0 ml-1 mr-1 float-right"
      onClick={e => {
        e.preventDefault();
        fetchS3Object(props);
      }}
    >
      {title}
    </button>
  );
}

const textToDataArray = text => {
  let results = Papa.parse(text, { header: true });
  return results.data;
};

export function LoadOrEditDataButton(props) {
  let { data, setData, doc, modalId, currentDocObject, readOnly, hide } = props;
  if (hide) {
    return null;
  }

  let docId = doc.get("uid");
  let current_url = currentDocObject ? currentDocObject.get("url") : null;

  let hasData = Traec.Im.isList(data) ? data.size > 0 : false;

  if (!hasData && current_url) {
    return (
      <LoadDataButton
        {...props}
        title={readOnly ? "View data" : "Edit data"}
        docId={docId}
        currentDocObject={currentDocObject}
        responseHandler={response =>
          response
            .text()
            .then(_text => {
              setData(Traec.Im.fromJS(textToDataArray(_text)));
              $(`#${modalId}`).modal("show");
            })
            .catch(e => {
              console.warn("ERROR LOADING DATA");
            })
        }
        failureHandler={error => {
          alertSuccess({
            title: "Error loading data",
            text:
              "There was an issue loading data for this form.  You will be presented with a new blank form to complete.  If you have issues or need to retrieve this data then please contact support."
          });
          $(`#${modalId}`).modal("show");
        }}
      />
    );
  }

  let action = readOnly ? "View" : hasData ? "Edit form" : "Add new form";
  return (
    <BSBtn
      text={`${action} data`}
      onClick={e => $(`#${modalId}`).modal("show")}
      extra_className="pl-1 pr-1 m-0 p-0 float-right"
      noFloatRight={true}
    />
  );
}

export function ReportDocumentFormButton(props) {
  let { trackerId, commitId, path, doc, rowColor, indentLevel, readOnly, docStatus } = props;
  let [data, setData] = useState(Traec.Im.List());

  if (!doc) {
    return null;
  }
 
  let formDetails = doc.getInPath("meta_json.input_details") || Traec.Im.Map();
  let formTemplate = doc.getInPath("meta_json.input_details.file_template") || Traec.Im.Map();
  let formFields = formDetails.get("fields");

  let modalId = `${doc.get("uid")}-form`;
  let modalTitle = formDetails.get("name");

  let noReport = docStatus?.getInPath("status.name") == "Not for Submission";

  let assignments = doc.getInPath("meta_json.assignments");

  return (
    <ReportRowErrorBoundary>
      <tr style={{ backgroundColor: rowColor }}>
        <td className="border-0">
          <DocumentTitleTooltip doc={doc} indentLevel={indentLevel} />
        </td>

        <td colSpan={3} className="border-0">
          <CurrentObject {...props} docId={doc.get("uid")} hide={noReport} />
          <LoadOrEditDataButton {...props} data={data} setData={setData} modalId={modalId} hide={noReport} />

          <BSModal
            id={modalId}
            title={modalTitle}
            fullWidth={true}
            body={
              <DataTable
                readOnly={readOnly}
                fields={formFields}
                formName={modalTitle}
                data={data}
                setData={setData}
                trackerId={trackerId}
                commitId={commitId}
                doc={doc}
                path={path}
                formTemplate={formTemplate}
                assignments={assignments}
              />
            }
          />
        </td>

        <td className="text-center border-0">
          <DocumentNoReportCheckbox {...props} disableInputs={readOnly} />
        </td>
        <td className="border-0">{/* Admin dropdown here */}</td>
      </tr>
    </ReportRowErrorBoundary>
  );
}
