import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { ErrorBoundary } from "traec-react/errors";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";

import {
  metricFields,
  frequencyFields,
  conversionFields,
  documentFields,
  placeholderFields,
  inputTypeFields
} from "./forms";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";
import Moment from "moment";

import { metricCounters } from "./index";

import { getNodeFromPath, getParentNodeFromPath } from "traec/utils/nodes";
import { SubNodes } from "./node";
import { nestBaseMetric } from "../report/reportMetricRow";
import { copyToCommitHandler } from "./utils";

import { setAndShowModal } from "AppSrc/utils/modal";

import { DragDropHandle } from "./dragHandle";

const MODAL_ID = "CommonMetricModal001";

export function RequiredStar({ score }) {
  if (!score) {
    return null;
  }
  let isRequired = score.get("required", false);
  //if (score.getInPath('metric.name').startsWith("Mains water")) {debugger}
  if (!isRequired) {
    return null;
  }
  return <span style={{ color: "red" }}>*</span>;
}

export function MetricFrequency({ score }) {
  if (!score) {
    return null;
  }
  let units = score.get("freq_unit");
  if (!units) {
    return null;
  }
  //
  let freq_num = score.get("freq_num");
  let from_date = score.get("from_date");
  let from_date_str = from_date ? `from ${Moment(from_date).format("Do MMM YY")}` : "";
  return `Every ${freq_num} ${units} ${from_date_str}`;
}

const copyToClipboard = text => {
  console.log("Copying text to clipboard: ", text);
  let dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

function MetricToolTip({ score }) {
  if (!score) {
    return null;
  }
  let metric = score.get("metric") || Traec.Im.Map();
  let descr = (metric.get("description") || "").trim();
  let baseMetricId = (metric.get("uid") || "").substring(0, 8);
  let metricScoreId = (score.get("uid") || "").substring(0, 8);
  return (
    <Tooltip
      animateFill={false}
      html={
        <div className="text-left">
          <div dangerouslySetInnerHTML={{ __html: descr }} />
          <p>
            BaseMetric id: {baseMetricId}
            <br />
            ReportInput id: {metricScoreId}
          </p>
        </div>
      }
    >
      <Octicon
        name="info"
        className="ml-2"
        onClick={e => copyToClipboard(`"baseMetricId": "${baseMetricId}",\n"metricScoreId": "${metricScoreId}"`)}
      />
    </Tooltip>
  );
}

export function MetricAlerts({ conversionFactor }) {
  if (conversionFactor && conversionFactor.get("factor") !== null) {
    return null;
  }

  let metricId = "";
  let toUnit = "";
  if (conversionFactor) {
    metricId = conversionFactor.getInPath("metric.uid") || "";
    toUnit = conversionFactor.get("toUnit");
  }

  return (
    <Tooltip
      animateFill={false}
      html={
        <div className="text-left">
          No conversion factor found from base metric (id: {metricId.substring(0, 8)}) found to parent unit "{toUnit}"
        </div>
      }
    >
      <Octicon className="text-danger" name="alert" />
    </Tooltip>
  );
}

function MetricAdminMenu(props) {
  let { subDocs, copyToCommit, links } = props;
  let docNumStr = subDocs ? (subDocs.size ? `(${subDocs.size})` : "") : "";

  // If we are displaying metrics in order to copy to another commit then show the copyTo button
  if (copyToCommit) {
    return (
      <a
        className="btn btn-sm btn-default m-0 p-0"
        style={{ cursor: "pointer" }}
        onClick={e => {
          copyToCommitHandler(props);
        }}
      >
        Add
      </a>
    );
  }
  // Otherwise return the full admin dropdown
  return (
    <BSBtnDropdown
      links={links}
      header={
        <span>
          {docNumStr} <Octicon name="gear" />
        </span>
      }
    />
  );
}

function addConversionFactor(props) {
  let { trackerId, commitId, path: pathId, conversionFactor = Traec.Im.Map() } = props;

  // Get the title of the modal
  let metricId = conversionFactor.getInPath("metric.uid") || "";
  let toUnit = conversionFactor.getInPath("toUnit");

  let fetch = new Traec.Fetch("tracker_commit_convfactor", "post", { trackerId, commitId });
  fetch.updateFetchParams({
    preFetchHook: data => {
      let _data = {
        ...data,
        metric: metricId,
        toUnit: toUnit
      };
      console.log("POSTING DATA", _data);
      return _data;
    },
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
    }
  });

  setAndShowModal(MODAL_ID, {
    title: `Add conversion factor from metric [${metricId.substring(0, 8)}] to ${toUnit}`,
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(conversionFields).toJS()}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

function editConversionFactor(props) {
  let { trackerId, commitId, path: pathId, conversionFactor = Traec.Im.Map() } = props;

  // Get the title of the modal
  let convFactorId = conversionFactor.get("uid");
  let metricId = conversionFactor.getInPath("metric.uid") || "";
  let toUnit = conversionFactor.getInPath("toUnit");

  let fetch = new Traec.Fetch("tracker_commit_convfactor", "patch", { trackerId, commitId, convFactorId });
  fetch.updateFetchParams({
    preFetchHook: data => {
      console.log("POSTING DATA", data);
      return data;
    },
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
    }
  });

  setAndShowModal(MODAL_ID, {
    title: `Edit conversion factor from metric [${metricId.substring(0, 8)}] to ${toUnit}`,
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(conversionFields).toJS()}
        initFields={conversionFactor}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

function deleteConversionFactor(props) {
  let { trackerId, commitId, conversionFactor = Traec.Im.Map() } = props;

  let convFactorId = conversionFactor.get("uid");

  let fetch = new Traec.Fetch("tracker_commit_convfactor", "delete", { trackerId, commitId, convFactorId });
  fetch.updateFetchParams({
    postSuccessHook: () => {
      location.reload();
    }
  });

  fetch.dispatch();
}

const toggleRequiredMetric = ({ score, trackerId, refId, commitId }) => {
  let pathId = score.get("_path");
  let isRequired = score.get("required");

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId
  });

  fetch.updateFetchParams({
    body: {
      type: "metricscore",
      node: {
        metricscore: {
          required: !isRequired
        }
      }
    }
  });
  fetch.dispatch();
};

const toggleHighlightMetric = ({ score, trackerId, refId, commitId }) => {
  let pathId = score.get("_path");
  let period = score.get("period") || -1;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId
  });

  fetch.updateFetchParams({
    body: {
      type: "metricscore",
      node: {
        metricscore: {
          period: period > 0 ? -1 : 1
        }
      }
    }
  });
  fetch.dispatch();
};

const setMetricMetaJson = ({ score, trackerId, refId, commitId }, meta_json) => {
  let pathId = score.get("_path");

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId
  });

  fetch.updateFetchParams({
    body: {
      type: "metricscore",
      node: {
        metricscore: {
          meta_json
        }
      }
    }
  });

  console.log("Setting metric meta_json", meta_json);
  fetch.dispatch();
};

const setNodeMeta = ({ trackerId, refId, commitId, path, setPending, modalId, nodeType }, meta_json) => {
  let fetch = new Traec.Fetch("tracker_node", "patch", { trackerId, refId, commitId, pathId: path });
  fetch.updateFetchParams({
    preFetchHook: body => {
      console.log("Setting metric meta_json", meta_json);
      return {
        type: nodeType,
        node: {
          [nodeType]: { meta_json }
        }
      };
    },
    postSuccessHook: e => {
      setPending(false);
      if (modalId) {
        $(`#${modalId}`).modal("hide");
      }
    },
    postFailureHook: data => {
      setPending(false);
    }
  });
  fetch.dispatch();
};

function editFrequency(props) {
  let { score, trackerId, refId, commitId, path: pathId } = props;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId
  });
  fetch.updateFetchParams({
    preFetchHook: data => {
      let _data = {
        type: "metricscore",
        node: {
          metricscore: {
            ...data,
            freq_unit: data.freq_unit || null,
            from_date: data.from_date || null
          }
        }
      };
      console.log("POSTING DATA", _data);
      return _data;
    },
    postSuccessHook: () => $(`#${MODAL_ID}`).modal("hide")
  });

  setAndShowModal(MODAL_ID, {
    title: "Edit Reporting Frequency",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(frequencyFields).toJS()}
        initFields={score}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

function editPlaceholder(props) {
  let { score, trackerId, refId, commitId, path: pathId } = props;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId
  });
  fetch.updateFetchParams({
    preFetchHook: data => {
      let _data = {
        type: "metricscore",
        node: {
          metricscore: {
            meta_json: { ...data }
          }
        }
      };
      console.log("POSTING DATA", _data);
      return _data;
    },
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
    }
  });

  setAndShowModal(MODAL_ID, {
    title: "Edit Placeholder Comment",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(placeholderFields).toJS()}
        initFields={score.get("meta_json")}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

export function SetNodeMetaJsonInput(props) {
  let { trackerId, refId, commitId, node, nodeType, modalId } = props;
  // Pretty-print the initial JSON with indent=4
  const inputEl = useRef(null);
  let initValue = JSON.stringify(node.getInPath("meta_json") || [], undefined, 4);
  let [value, setValue] = useState(initValue);
  let [isValid, setIsValid] = useState(true);
  let [pending, setPending] = useState(false);
  let [charsToCursor, setCharsToCursor] = useState((initValue || "").length);

  const setCursorPos = _value => {
    if (!isValid) {
      return;
    }
    let _textArea = inputEl.current;
    console.log("Setting cursor position", _value.length, charsToCursor);
    // Set the cursor position to the same character-position (ignoring whitespaces)
    let nChars = 0;
    for (let i = 0; i < _value.length; i++) {
      if (_value[i] !== " ") {
        nChars++;
        if (nChars == charsToCursor) {
          console.log("Setting cursor position to", i + 1);
          _textArea.selectionStart = i + 1;
          _textArea.selectionEnd = i + 1;
          break;
        }
      }
    }
  };

  // This sets cursor position when value is updated
  useEffect(() => {
    console.log("Calling setCursorPos", charsToCursor);
    setCursorPos(value);
  }, [value]);

  const onChangeHandler = e => {
    e.preventDefault();
    let str = e.target.value;
    try {
      let _pos = str.substring(0, inputEl.current.selectionEnd + 1).replace(/ /g, "").length;
      // Get the valid string
      let validStr = JSON.stringify(JSON.parse(str), undefined, 4);
      //console.log("String is valid JSON", validStr)
      // Set the string
      console.log("Set characters before cursor", _pos);
      setCharsToCursor(_pos);
      setIsValid(true);
      setValue(validStr);
    } catch (e) {
      //console.log("Invalid JSON", str, e)
      setIsValid(false);
      setValue(str);
    }
  };

  return (
    <React.Fragment>
      <textarea
        ref={inputEl}
        className="form-control text-monospace"
        rows="20"
        onChange={onChangeHandler}
        value={value}
      />
      {!isValid ? (
        <small className="text-danger">
          <b>Enter valid json</b>
        </small>
      ) : null}
      <button
        className="btn btn-sm btn-primary pl-2 pr-2 m-1 p-0 float-right"
        disabled={!isValid}
        onClick={e => {
          e.preventDefault();
          let _value = JSON.parse(value);
          console.log("Setting node meta_json", _value);
          setNodeMeta(
            {
              nodeType,
              trackerId,
              refId,
              commitId,
              modalId,
              setPending,
              path: node.get("_path")
            },
            _value
          );
        }}
      >
        {pending ? <div className="spinner-border spinner-border-sm" /> : "Save"}
      </button>
    </React.Fragment>
  );
}

const editMetajson = props => {
  let modalId = "CommonNodeMetaModal001";
  setAndShowModal(modalId, {
    title: "Edit node meta-data",
    immutableBodyProps: true,
    body: <SetNodeMetaJsonInput {...props} modalId={modalId} node={props.score} nodeType={"metricscore"} />
  });
};

function setInputType(props) {
  let { score, trackerId, refId, commitId, path: pathId } = props;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId
  });
  fetch.updateFetchParams({
    preFetchHook: data => {
      let _data = {
        type: "metricscore",
        node: {
          metricscore: {
            meta_json: data
          }
        }
      };
      console.log("POSTING DATA", _data);
      return _data;
    },
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
    }
  });

  setAndShowModal(MODAL_ID, {
    title: "Set Input Type",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(inputTypeFields).toJS()}
        initFields={score.get("meta_json")}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

function addSubMetric(props) {
  let { trackerId, refId, commitId, path: pathId, categoryName } = props;
  console.log("Adding metric for category", categoryName)

  let fetch = new Traec.Fetch("tracker_node", "post", {
    trackerId,
    refId,
    commitId,
    pathId
  });
  fetch.updateFetchParams({
    preFetchHook: data => ({
      path: pathId,
      type: "metricscore",
      node: {
        metricscore: {
          metric: {
            ...data,
            category: categoryName
          }
        }
      }
    }),
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
    }
  });

  setAndShowModal(MODAL_ID, {
    title: "Add a new sub-metric",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(metricFields).toJS()}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

function addDocument(props) {
  let { trackerId, refId, commitId, path: pathId } = props;

  let fetch = new Traec.Fetch("tracker_node", "post", {
    trackerId,
    refId,
    commitId,
    pathId
  });
  fetch.updateFetchParams({
    preFetchHook: data => ({
      path: pathId,
      type: "document",
      node: {
        document: {
          name: data.name || data.title,
          description: {
            title: data.title,
            text: data.description
          }
        }
      }
    }),
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
    }
  });

  setAndShowModal(MODAL_ID, {
    title: "Add a new file upload",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(documentFields).toJS()}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

function editMetric(props) {
  let { score, trackerId, refId, commitId, path: pathId } = props;
  if (!score) {
    return null;
  }
  let baseMetricId = score.getInPath("metric.uid");

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId
  });
  fetch.updateFetchParams({
    preFetchHook: data => ({
      type: "metricscore",
      node: {
        metricscore: {
          metric: {
            ...data,
            uid: baseMetricId
          }
        }
      }
    }),
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
    }
  });

  let initFields = (score || Traec.Im.Map()).merge(score.get("metric"));

  setAndShowModal(MODAL_ID, {
    title: "Edit metric",
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={{ ...metricFields }} // This is converted to Immutable when Modal props are stored in Redux
        initFields={initFields}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
}

const deleteNode = props => {
  let { score, trackerId, refId, commitId, path: pathId } = props;

  let name = score ? score.getInPath("metric.name") : "";

  confirmDelete({
    text: `This will delete the Metric: ${name} including any sub-metrics and documents contained within.  Are you sure you would like to proceed?`,
    onConfirm: () => {
      new Traec.Fetch("tracker_node", "delete", { trackerId, refId, commitId, pathId }).dispatch();
    }
  });
};

class MetricRow extends React.Component {
  constructor(props) {
    super(props);

    let { trackerId, refId, commitId, path } = props;
    let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, refId, commitId, path });

    this.state = {
      showDocs: true,
      formParams: fetch.params,
      formFields: Traec.Im.fromJS(metricFields).toJS()
    };

    this.addConversionFactor = addConversionFactor.bind(this);
    this.editConversionFactor = editConversionFactor.bind(this);
    this.deleteConversionFactor = deleteConversionFactor.bind(this);
    this.editFrequency = editFrequency.bind(this);
    this.addSubMetric = addSubMetric.bind(this);
    this.editMetric = editMetric.bind(this);
    this.addDocument = addDocument.bind(this);
    this.editPlaceholder = editPlaceholder.bind(this);
    this.setInputType = setInputType.bind(this);
  }

  dropDownLinks() {
    let { conversionFactor, score } = this.props;
    let { showDocs } = this.state;

    score = score || Traec.Im.Map();
    let isHidden = score.getInPath("meta_json.hidden") || false;

    let hideChildrenIfNullOrZero = score.getInPath("meta_json.hideChildrenIfNullOrZero") || false;
    let calculated = score.getInPath("meta_json.calculated");
    let disableManualEntry = score.getInPath("meta_json.disable_manual_entry");
    calculated = calculated === undefined ? true : calculated;

    let items = [
      {
        name: "Toggle Required",
        onClick: () => {
          toggleRequiredMetric(this.props);
        }
      },
      {
        name: "Toggle Highlight",
        onClick: () => {
          toggleHighlightMetric(this.props);
        }
      },
      {
        name: `${isHidden ? "Show" : "Hide"} in Report Due`,
        onClick: () => {
          setMetricMetaJson(this.props, { hidden: !isHidden });
        }
      },
      {
        name: `${hideChildrenIfNullOrZero ? "Show" : "Hide"} children if no value`,
        onClick: () => {
          setMetricMetaJson(this.props, { hideChildrenIfNullOrZero: !hideChildrenIfNullOrZero });
        }
      },
      {
        name: `${calculated ? "Manual Entry" : "Calculate from children"}`,
        onClick: () => {
          setMetricMetaJson(this.props, { calculated: !calculated });
        }
      },
      {
        name: "Set Frequency",
        onClick: () => {
          this.editFrequency(this.props);
        }
      },
      {
        name: "Set Placeholder",
        onClick: () => {
          this.editPlaceholder(this.props);
        }
      },
      {
        name: "Edit raw meta-data",
        onClick: () => {
          editMetajson(this.props);
        }
      },
      {},
      {
        name: "Set Input Type",
        onClick: () => {
          this.setInputType(this.props);
        }
      },
      {},
      {
        name: "Edit this Metric",
        onClick: () => {
          this.editMetric(this.props);
        }
      },
      {
        name: "Add sub-Metric",
        onClick: () => {
          this.addSubMetric(this.props);
        }
      },
      {},
      {
        name: "Add File Upload",
        onClick: () => {
          this.addDocument(this.props);
        }
      },
      {
        name: `${showDocs ? "Hide" : "Show"} File Uploads`,
        onClick: e => {
          this.setState({ showDocs: !showDocs });
        }
      }
    ];

    // Add section relating to conversion factors
    if (!conversionFactor || !conversionFactor.get("uid")) {
      // Add a conversion factors
      items.push({});
      items.push({
        name: "Add conversion factor",
        onClick: () => {
          this.addConversionFactor(this.props);
        }
      });
    } else if (conversionFactor && conversionFactor.get("toUnit") && conversionFactor.get("uid")) {
      items.push({});
      items.push({
        name: "Edit/show conversion factor",
        onClick: () => {
          this.editConversionFactor(this.props);
        }
      });
      if (!conversionFactor.get("is_global") && conversionFactor.get("uid")) {
        items.push({
          name: "Delete local conversion factor",
          onClick: () => {
            this.deleteConversionFactor(this.props);
          }
        });
      }
    }

    if (calculated) {
      items.push({
        name: `${disableManualEntry ? "Enable" : "Disable"} Manual Entry`,
        onClick: () => {
          setMetricMetaJson(this.props, { disable_manual_entry: !disableManualEntry });
        }
      });
    }

    // Add delete section
    items.push({});
    items.push({
      name: "Delete",
      onClick: e => {
        deleteNode(this.props);
      }
    });

    return items;
  }

  /**********************
   RENDER METHODS
   **********************/

  render_link() {
    let { tree, score, hasChildren } = this.props;
    let treeId = tree.get("uid");
    if (hasChildren) {
      return (
        <a href={`#${treeId}`} onClick={this.props.onCollapseClick}>
          <Octicon className="expand_caret" name={this.props.collapsed ? "triangle-right" : "triangle-down"} />
          {score.getInPath("metric.name")}
        </a>
      );
    }
    return score ? score.getInPath("metric.name") : score;
  }

  render() {
    let bgColor = "";
    let { score, path, trackerId, indentLevel = 0, conversionFactor, sortKey } = this.props;
    let { showDocs } = this.state;

    if (!score) {
      return null;
    }

    let highlight = (score.get("period") || -1) > 0;

    let rowNum = metricCounters.row++;

    return (
      <React.Fragment>
        <div
          className={`row ${bgColor} ${highlight ? "font-weight-bold" : ""}`}
          style={{
            borderTop: "1px solid #ddd",
            backgroundColor: (rowNum + 1) % 2 ? "#E6E6E6" : ""
          }}
        >
          <div className="col-sm-6">
            <DragDropHandle trackerId={trackerId} path={path} />
            <span style={{ margin: "0", marginLeft: `${indentLevel * 1.5}em` }}>{this.render_link()}</span>
            <RequiredStar score={score} />
            <MetricToolTip score={score} />
          </div>
          <div className="col-sm-3">
            <MetricFrequency score={score} />
          </div>
          <div className="col-sm-2">{score.getInPath("metric.unit")}</div>
          <div className="col-sm-1 text-right">
            <ErrorBoundary title={null} className="badge badge-warning" msg={<Octicon name="bug" />}>
              <MetricAlerts conversionFactor={conversionFactor} />
              <MetricAdminMenu {...this.props} links={this.dropDownLinks()} />
            </ErrorBoundary>
          </div>
        </div>
        <SubNodes {...this.props} hide={!showDocs} />
      </React.Fragment>
    );
  }
}

export const getConversionFactor = ({ score, parentScore, convFactorDetails = {} }) => {
  let { convFactorMap = {} } = convFactorDetails;

  if (!score || !parentScore) {
    // Return a dummy conversion factor with factor = 1 and toUnit == null
    return Traec.Im.fromJS({
      factor: 1,
      metric: score ? score.get("metric") : null,
      toUnit: null
    });
  }

  let fromUnit = score.getInPath("metric.unit");
  let parentUnit = parentScore.getInPath("metric.unit");
  let metricId = score.getInPath("metric.uid");

  // If there is a valid conversion factor (even between the same units) then use that
  let conversionObject = convFactorMap ? convFactorMap[[metricId, parentUnit]] : null;

  let dummyConversionObject = Traec.Im.fromJS({
    factor: null,
    metric: score.get("metric"),
    toUnit: parentUnit
  });

  if (conversionObject) {
    // Return the valid conversion object (if we have it)
    return conversionObject;
  } else if (fromUnit === parentUnit) {
    // Return a dummy conversion of "1" if the units are identical
    return dummyConversionObject.set("factor", 1);
  } else {
    // Return conversion with a factor of null when there is no way to convert
    return dummyConversionObject;
  }
  // We shouldn't get here
  return dummyConversionObject;
};

const mapStateToProps = (state, ownProps) => {
  let { path, commitNodes, convFactorDetails } = ownProps;

  let score = nestBaseMetric(state, getNodeFromPath(state, path, commitNodes));

  // Get the conversion factor
  let parentScore = nestBaseMetric(state, getParentNodeFromPath(state, path, commitNodes, "metricScores"));
  let conversionFactor = getConversionFactor({ score, parentScore, convFactorDetails });

  return { score, parentScore, conversionFactor };
};

const MetricRowConnected = connect(mapStateToProps)(MetricRow);
export default MetricRowConnected;
