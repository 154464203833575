import React from "react";
import { connect } from "react-redux";

import Traec from "traec";
import { getNodeFromPath } from "traec/utils/nodes";
import { ErrorBoundary } from "traec-react/errors";
import { nestBaseMetric } from "AppSrc/project/report/reportMetricRow";

import Tree from "./treeRow";
import Metric from "./metricRow";
import Document from "./documentRow";

/* 
Component for rendering whatever node type is at a row
*/

export function NodeRow(props) {
  let { commitNodes, path } = props;

  let id = commitNodes.getInPath(`byPath.${path}.uid`);
  let _type = commitNodes.getInPath(`byPath.${path}.type`);

  return null;
}

function ComponentNotFound({ path, type }) {
  return (
    <div className="row">
      <div className="col-sm-12">
        Component not found for node of type {type} at path {path}
      </div>
    </div>
  );
}

export function _SubNodes(props) {
  let { commitNodes, child_paths, indentLevel = 0, hide, componentMap, showOnly } = props;
  if (hide) {
    return null;
  }

  // This is done in mapStateToProps in order to do Ordering on nested fields.
  // But we could do it here if we ordered on path only.
  const COMPONENT_MAP = componentMap || {
    metricscore: Metric,
    tree: Tree,
    document: Document
  };

  //console.log("RENDERNIG SUB-NODES AT PATH", path, childPaths.toJS())

  let child_components = child_paths.map((_path, i) => {
    // If we have showOnly passed as a prop then only render children that are in showOnly
    if (showOnly !== undefined && showOnly[_path] == undefined) {
      return null;
    }

    let _type = commitNodes.getInPath(`byPath.${_path}.type`);
    let ChildComponent = COMPONENT_MAP[_type];

    // If the node type does not match a valid type that we have a component for then render a message
    if (!ChildComponent) {
      return <ComponentNotFound key={i} path={_path} type={_type} />;
    }

    // Render the sub-component in an ErrorBoundary
    return (
      <ErrorBoundary
        key={i}
        title={null}
        className="row alert alert-warning pt-0 pb-0 mt-1 mb-0"
        msg={
          <span>
            Error rendering row for {_type} at {_path}
          </span>
        }
      >
        <ChildComponent
          {...props}
          indentLevel={indentLevel + 1}
          path={_path}
          hidden={showOnly ? !showOnly[_path] : undefined}
        />
      </ErrorBoundary>
    );
  });

  return child_components;
}

const mapStateToProps = (state, ownProps) => {
  let { commitNodes, path, sortKey } = ownProps;

  let child_paths = commitNodes.getInPath(`byPath.${path}.children.byPath`) || Traec.Im.Set();

  if (sortKey) {
    let child_entities = child_paths
      .map(path => nestBaseMetric(state, getNodeFromPath(state, path, commitNodes)))
      .filter(i => i)
      .sortBy(i => i.getInPath(sortKey || "name") || i.get("name"));

    child_paths = child_entities.map(i => i.get("_path"));
  }

  return { child_paths };
};

export const SubNodes = connect(mapStateToProps)(_SubNodes);
