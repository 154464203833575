import React, { useState, useEffect } from "react"

import { ErrorBoundary } from "storybook-dashboard/errors";
import IssuesRAGPanel from "storybook-dashboard/dashboard/ragPanels/issues"
import IndicatorsRAGPanel from "storybook-dashboard/dashboard/ragPanels/indicators"
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar"
import ProjectReportCommitStatus from "storybook-dashboard/dashboard/project/reportStatus"
import IndicatorPerformanceTable from "storybook-dashboard/dashboard/performanceTable";
import InputValuesTable from "storybook-dashboard/dashboard/project/inputValues";
import DataExplorer from "storybook-dashboard/dashboard/components/dataExplorer";

import Im from "immutable"

function Hider({children, hide}) {
    return hide ? null : children
}

const explorerOptionsProject = {
  metric_values: {
      text: "Performance breakdown table",
      component: IndicatorPerformanceTable
  },
  performance: {
      text: "Input values table",
      component: InputValuesTable}
}

const explorerOptionsReportingPackage = {
  performance: {
      text: "Input values table",
      component: InputValuesTable}
}

export default function DashboardComponents({projectId, refId, iconPath, filters}) {
    let [selectedIssue, setSelectedIssue] = useState(null)
    let [selectedIndicatorIds, setSelectedIndicatorIds] = useState(Im.Map())
  
    // Reset the selected indicators to none if the issue selection changes
    useEffect(() => {
      setSelectedIndicatorIds(Im.Map())
    }, [selectedIssue])

    // Simplify the selection of indicators to just give a list of objects {uid, name}
    let selectedIndicators = selectedIndicatorIds.toList()
  
    return (
      <ErrorBoundary>
        <ErrorBoundary>
          <IssuesRAGPanel 
            projectId={projectId} 
            refId={refId}
            iconPath={iconPath}
            selected={selectedIssue}
            setSelected={setSelectedIssue}
            filters={filters}
          />
        </ErrorBoundary>
  
        <ErrorBoundary>
          <IndicatorsRAGPanel 
            projectId={projectId} 
            refId={refId}
            iconPath={iconPath}
            issue={selectedIssue}
            selected={selectedIndicatorIds}
            setSelected={setSelectedIndicatorIds}          
            filters={filters}
          />
        </ErrorBoundary>
  
        <ErrorBoundary>
          <IndicatorsBarChart 
            projectId={projectId} 
            refId={refId}
            indicators={selectedIndicators}
            filters={filters}
          />
        </ErrorBoundary>
  
        <ErrorBoundary>
          <DataExplorer 
              projectId={projectId}
              refId={refId}
              indicators={selectedIndicators}
              options = {refId == "root" ? explorerOptionsProject : explorerOptionsReportingPackage}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <div className="row">
            <ProjectReportCommitStatus hide={refId != "root"} projectId={projectId}/>
          </div>
        </ErrorBoundary>
      </ErrorBoundary>
    )
  }
