import React from "react";
import { useState, useRef } from "react";
import Octicon from "react-octicon";
import "./uploadFileDragDrop.css";

export default function UploadFileDragDrop({ droppedFileHandler, onChangeHandler, error, header, value }) {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      console.log("file dropped handledrop");
      if (droppedFileHandler) {
        droppedFileHandler(e.dataTransfer.files);
        // onChangeHandler(e);
      } else {
        console.warn("No droppedFileHandler for component");
      }
    }
  };

  const handleChange = e => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      console.log("file dropped handlechange", e.target.files[0]);
      droppedFileHandler([e.target.files[0]]);
      // onChangeHandler(e);
      console.log("onchangehandler >>>", onChangeHandler);
    }
  };

  //for keyboard-only users
  const inputRef = useRef(null);
  const onButtonClick = () => {
    inputRef.current.click();
  };

  let labelClasses = `form-control form-control-sm ${error ? "is-invalid" : ""}`;
  return (
    <div id="formDragUpload" onDragEnter={handleDrag} onSubmit={e => e.preventDefault()}>
      <input
        type="file"
        multiple={true}
        onChange={handleChange}
        ref={inputRef}
        name={header}
        value={value}
        id="inputDragUpload"
        className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
      />
      <label id="labelDragUpload" htmlFor="inputDragUpload" className={dragActive ? " dragActive" : ""}>
        <div>
          <Octicon mega name="cloud-upload" />
          <p>
            <b>Drag and drop a file to upload or</b>
          </p>
          <button className="btn btn-secondary" onClick={onButtonClick}>
            Select file
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          id="invisibleDragListener"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  );
}
